import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import OnbImg from '../../Images/image bg.png';
import classes from "./LatestDashboard.module.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Modal, Form } from "react-bootstrap";
// import loanicon from '../../Images/moneys.png'
// import loaniconblue from '../../Images/moneysblue.png'
// import loanicongreen from '../../Images/receipt-2.png'
// import axios from 'axios';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, ProgressBar } from "react-bootstrap";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Link } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";
import MainDashboard from "../Main Dashboard/MainDashoard";
import dChart1 from "../../assets/promix/dShart1.svg";
import dChart2 from "../../assets/promix/dShart2.svg";
import dChart3 from "../../assets/promix/dShart3.svg";
import dChart4 from "../../assets/promix/dShart4.svg";
import dChart5 from "../../assets/promix/dShart5.svg";
import dChart6 from "../../assets/promix/dShart6.svg";
import dChart7 from "../../assets/promix/dShart7.svg";
import dChart8 from "../../assets/promix/dShart8.svg";
import Arrow from "../../assets/promix/dArrow-down.svg";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CoopDashboard from "../Cooperative Dashboard/CoopDashboard";
import ReportDashboard from "../ReportDashboard/ReportDashboard";
import CashDash from "../Cash Dash/CashDash";
import ImageIcon from "../../assets/promix/Group 1.png";
import { Chart } from "react-charts";
import LatestMenu from "./LatestMenu";
import axios from "axios";
import { Spinner } from "react-bootstrap";
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from "../api/api";
import icon7 from "../../Pages/LatestDashboard/Dropeda.svg";
// import icon7 from '../../assets/promix/vuesax/log.png';
import Logo from "../../assets/nw/patna-cmas.png";
import Swal from "sweetalert2";

const LatestDash = ({setOpenNav,openNav}) => {
  const navigate = useNavigate();
  const [monthLoading, setMonthLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2648, setShow2648] = useState(false);
  const [currentPassword, setcurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [createLoading, setCreateLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleClose124 = () => {
    setShow2648(false);
  };

  const handleClose1 = () => {
    setShow1(false);
  };

  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleShow3123 = () => setShow2648(true);
  const [bearer, setBearer] = useState("");
  const [user, setUser] = useState("");
  const [company, setCompany] = useState("");
  const [userType, setUserType] = useState("");
  const [continent, setContinent] = useState("");
  const [region, setRegion] = useState("");
  const [province, setProvince] = useState("");

  const [validity, setValidity] = useState("");
  const [permits, setPermits] = useState([]);
  const [admin, setAdmin] = useState("");
  const [currMonth, setCurrMonth] = useState("");
  const [loading, setLoading] = useState(false);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      // const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem("admin");
      const value7 = await AsyncStorage.getItem("companyName");
      const value8 = await AsyncStorage.getItem("validity");
      const curr = await AsyncStorage.getItem("currentMonth");
      const permitsss = await AsyncStorage.getItem("modules");

      if (value !== null && value.trim() !== "") {
        setBearer(value);
      } else {
        // If bearer token is missing, navigate to login screen
        navigate("/login");
        return; // Stop further execution
      }
      if (permitsss !== null) {
        const parsedPermits = JSON.parse(permitsss);
        const permitNames = parsedPermits.map((permit) => permit.name);
        setPermits(permitNames);
        // console.log(permitNames);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value8 !== null) {
        setValidity(value8);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (value7 !== null) {
        setCompany(value7);
      }
      if (curr !== null) {
        setCurrMonth(curr);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };

  const data = [
    {
      label: "Money In",
      data: [
        { primary: "March 2024", secondary: 3_200_000 },
        { primary: "April 2024", secondary: 2_800_000 },
        { primary: "May 2024", secondary: 3_600_000 },
        { primary: "June 2024", secondary: 4_000_000 },
      ],
    },
    {
      label: "Money Out",
      data: [
        { primary: "March 2024", secondary: 1_500_000 },
        { primary: "April 2024", secondary: 2_000_000 },
        { primary: "May 2024", secondary: 2_500_000 },
        { primary: "June 2024", secondary: 3_000_000 },
      ],
    },
  ];

  const chartOptions = {
    primaryAxis: {
      getValue: (datum) => datum.primary,
      type: "ordinal",
    },
    secondaryAxes: [
      {
        getValue: (datum) => datum.secondary,
        elementType: "bar",
        min: 1_000_000,
        max: 4_000_000,
        tickFormat: (d) => `${(d / 1_000_000).toFixed(1)}M`,
      },
    ],
    getSeriesStyle: (series) => ({
      color: series.label === "Money In" ? "#2D995F" : "#FFBA08",
    }),
  };

  const Legend = ({ data }) => (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
    >
      {data.map((series) => (
        <div
          key={series.label}
          style={{ display: "flex", alignItems: "center", margin: "0 10px" }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor:
                series.label === "Money In" ? "#2D995F" : "#FFBA08",
              marginRight: "10px",
            }}
          ></div>
          <span>{series.label}</span>
        </div>
      ))}
    </div>
  );

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  const handleLogout = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      navigate("/login");
      //   toast.success(response.data.message);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (error.response) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === "string") {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join("; ");
          } else if (typeof error.response.data.message === "object") {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
      }
      //   toast.error(errorMessage);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const changePassword122 = async () => {
    setCreateLoading(true);
    try {
      console.log(currentPassword, password, passwordConfirmation);

      const response = await axios.post(
        `${BASE_URL}/change_password`,
        {
          current_password: currentPassword,
          new_password: password,
          password_confirmation: passwordConfirmation,
        },
        { headers }
      );
      console.log(response);

      setcurrentPassword("");
      setPassword("");
      setPasswordConfirmation("");
      handleClose();
      // setSelectType('');
      // return
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      // toast.success(response.data.message);
      console.log(response.data);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: errorMessage,
        });
        // toast.error(JSON.stringify(error.response.data.data));
        console.log(error);
      }
    } finally {
      setCreateLoading(false);
    }
  };

  const getNextMonthName = (currMonth) => {
    // Parse the currMonth string to create a Date object
    const date = new Date(currMonth);

    // Extract the current month (0-based index, so adding 1 to get the next month)
    let nextMonth = date.getMonth() + 1; // getMonth() is 0-based (Jan = 0)

    // If the next month is December (11) and we need to roll over to January
    if (nextMonth > 11) {
      nextMonth = 0;
    }

    // Create a new date object for the next month (day set to 1 for simplicity)
    const nextMonthDate = new Date(date.getFullYear(), nextMonth, 1);

    // Use toLocaleString() to get the month name in words
    const nextMonthName = nextMonthDate.toLocaleString("default", {
      month: "long",
    });

    return nextMonthName;
  };

  const getPrevMonthName = (currMonth) => {
    // Parse the currMonth string to create a Date object
    const date = new Date(currMonth);

    // Extract the current month and subtract 1 to get the previous month
    let prevMonth = date.getMonth() - 1; // getMonth() is 0-based (Jan = 0)

    // If the previous month is before January, roll over to December of the previous year
    if (prevMonth < 0) {
      prevMonth = 11; // December
      date.setFullYear(date.getFullYear() - 1); // Move to the previous year
    }

    // Create a new date object for the previous month (day set to 1 for simplicity)
    const prevMonthDate = new Date(date.getFullYear(), prevMonth, 1);

    // Use toLocaleString() to get the month name in words
    const prevMonthName = prevMonthDate.toLocaleString("default", {
      month: "long",
    });

    return prevMonthName;
  };

  const getCurrentMonthName = (currMonth) => {
    // Parse the currMonth string to create a Date object
    const date = new Date(currMonth);

    // Use toLocaleString() to get the current month name in words
    const currentMonthName = date.toLocaleString("default", { month: "long" });

    return currentMonthName;
  };

  const handleTrashClick1 = async () => {
    const currentMonthName = getCurrentMonthName(currMonth);
    const nextMonthName = getNextMonthName(currMonth);
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: `You are about to change your working month from ${currentMonthName} to ${nextMonthName}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Change it!",
      cancelButtonText: "No, Cancel",
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    // Show a Swal saying "Changing the working month, please wait..." and prevent interaction
    Swal.fire({
      title: "Changing the working month...",
      text: "Please wait while we process your request.",
      icon: "info",
      allowOutsideClick: false,
      showConfirmButton: false, // Hide the confirm button
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      },
    });

    setMonthLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/provinces/update-province-month`,
        {}, // Empty body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      // Close the loading Swal and show the success message
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      navigate("/login");
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }

      // Close the loading Swal and show the error message
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setMonthLoading(false); // Stop loading
    }
  };

  const handleTrashClick2 = async () => {
    const currentMonthName = getCurrentMonthName(currMonth);
    const prevMonthName = getPrevMonthName(currMonth);
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: `You are about to reset your working month from ${currentMonthName} to ${prevMonthName}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reset it!",
      cancelButtonText: "No, Cancel",
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    // Show a Swal saying "Changing the working month, please wait..." and prevent interaction
    Swal.fire({
      title: "Resetting the working month...",
      text: "Please wait while we process your request.",
      icon: "info",
      allowOutsideClick: false,
      showConfirmButton: false, // Hide the confirm button
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      },
    });

    setMonthLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/provinces/reverse-province-month`,
        {}, // Empty body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      navigate("/login");
      //   setTrashClicked1(true);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setMonthLoading(false); // Stop loading
    }
  };

  return (
    <div>
      
      <div className={classes.latestBody}>
        <div className={classes.latestBodyContainer}>
          <div className={classes.formSection}>
            <div className={classes.formSectionHeader}>
              <div className={classes.formSectionHeaderContents}>
                {/* <div className={classes.ContactInfo}>
                                        <div className={classes.inforConts}>
                                            <p>Mail Us at: <span>info@brookessoftware.com </span>  or Call Us at: <span> +2348161241827,  +2348060202011</span>  </p>
                                        </div> 
                                    </div> */}
                <div className={classes.horinfodashbrd}>
                  <div className={classes.stinfo}>
                    <img src={Logo} alt="Icon" className={classes.logoStyle} />
                    <p className={classes.stinfosts}>
                      <b>
                        Professionally Built Church Management and Accounting
                        System
                      </b>
                    </p>
                    {/* <p className={classes.stinfolvl}>{user.toLocaleUpperCase()}</p> */}
                  </div>
                  <div className={classes.usrcnr}>
                    {/* <div className={classes.vrtlne}></div> */}
                    <div className={classes.usrinfo}>
                      <div className={classes.usrnminf}>
                        {/* <p className={classes.usrpstn}>Company Name</p> */}
                        <p className={classes.usrnme}>
                          {user.toLocaleUpperCase()}
                        </p>
                        <p
                          style={{
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            marginTop: -5,
                          }}
                        >
                          {" "}
                          {admin}{" "}
                        </p>
                        {/* <p className={classes.usrnme}>{company.toLocaleUpperCase()}</p> */}
                        {/* <p className={classes.usrpstn}>Valid till {formatDate(validity)}</p> */}
                      </div>
                      <div className={classes.avtrds}>
                        <Dropdown>
                          <Dropdown.Toggle
                            className={classes.Dropdowns}
                            id="dropdown-receivables"
                          >
                            <span>
                              <img
                                src={icon7}
                                className={classes.avtrdfs}
                                alt="Icon"
                              />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className={classes.DropdownMenu}>
                            {/* <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Stock</Button> */}
                            <Dropdown.Item as={Link} to="/welcome">
                              Switch Account
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleShow}>
                              Change Password
                            </Dropdown.Item>
                            {admin !== "Super Admin" && (
                              <>
                                <Dropdown.Item onClick={handleTrashClick1}>
                                  Change Working Month
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleTrashClick2}>
                                  Reset Working Month
                                </Dropdown.Item>
                              </>
                            )}
                            <Dropdown.Item onClick={() => handleLogout()}>
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {/* <div className={classes.avtrs} onClick={() => handleLogout()} >

                                                <>

                                                    {loading ? <Spinner size="sm" animation="border" variant="light" /> :
                                                        <img src={icon7} alt="Icon" className={classes.avtrs} />
                                                    }
                                                </>
                                            </div> */}
                    </div>

                    {/* <img src={bell} className={classes.bel} /> */}
                  </div>
                  <div className={classes.menuBar}>
                 <button onClick={() => {setOpenNav(true) 
                  console.log("click")}}> <IoMdMenu /></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.formSectionHeader2}>
        <LatestMenu />
      </div>
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ marginBottom: 20 }}>
            <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Current Password"
                value={currentPassword}
                onChange={(e) => setcurrentPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Confirm New Password"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Modal.Footer>
            {/* <Button variant="danger" onClick={handleClose}>
                Close
                  </Button> */}
            <Button onClick={handleClose} variant="danger">
              Cancel
            </Button>
            <Button
              variant="success"
              className={classes.btn2}
              onClick={changePassword122}
            >
              {createLoading ? (
                <>
                  <Spinner size="sm" />
                  <span style={{ marginLeft: "5px" }}>
                    Processing, Please wait...
                  </span>
                </>
              ) : (
                "Change Password"
              )}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      <Modal show={show2648} onHide={handleClose124} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ marginBottom: 20 }}>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Full name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter full name of team member"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email address" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Phone number</Form.Label>
              <Form.Control type="number" placeholder="Enter phone number" />
            </Form.Group>
            <Form.Label>Roles and permissions</Form.Label>
            <div className={classes.checkmarg}>
              {["checkbox"].map((type) => (
                <div className="mb-3">
                  <Form.Check type={type} id={`check-api-${type}`}>
                    <Form.Check.Input type={type} isValid />
                    <Form.Check.Label>{`Income`}</Form.Check.Label>
                  </Form.Check>
                  <Form.Check type={type} id={`check-api-${type}`}>
                    <Form.Check.Input type={type} isValid />
                    <Form.Check.Label>{`Receivables`}</Form.Check.Label>
                  </Form.Check>
                  <Form.Check type={type} id={`check-api-${type}`}>
                    <Form.Check.Input type={type} isValid />
                    <Form.Check.Label>{`Expenses`}</Form.Check.Label>
                  </Form.Check>
                  <Form.Check type={type} id={`check-api-${type}`}>
                    <Form.Check.Input type={type} isValid />
                    <Form.Check.Label>{`Payables`}</Form.Check.Label>
                  </Form.Check>
                  <Form.Check type={type} id={`check-api-${type}`}>
                    <Form.Check.Input type={type} isValid />
                    <Form.Check.Label>{`Accounting`}</Form.Check.Label>
                  </Form.Check>
                </div>
              ))}
            </div>
          </Form>
          <Modal.Footer>
            {/* <Button variant="danger" onClick={handleClose}>
                Close
                  </Button> */}
            <Button variant="success">Submit</Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LatestDash;
