import React, { useState, useEffect } from 'react';
import classes from './PrintFixed.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useLocation } from 'react-router-dom';


export default function PrintFixedAsset() {
  const [bearer, setBearer] = useState('');
  const [company, setCompany] = useState('');
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');
  const [groupedData, setGroupedData] = useState([]);



  useEffect(() => {
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = addLeadingZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    const minutes = addLeadingZero(currentDate.getMinutes());
    const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
    const seconds = addLeadingZero(currentDate.getSeconds());
    const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} `;

    setCurrentDateTime(formattedDateTime);
  }, []);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('companyName');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');
      const admins = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
        // setAuthenticated(true);
      }
      if (value1 !== null) {
        setCompany(value1);

      }

      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (admins !== null) {
        setUserType(admins);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();

  }, []);

  const { tableData} = location.state || {};
  const { allReport } = location.state || {};
  // console.log(tableData,true,allReport)


  const getOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return v >= 11 && v <= 13
      ? 'th'
      : suffixes[v % 10] || 'th';
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  };

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      const grouped = tableData.reduce((acc, item) => {
        const categoryID = item.category_id;
        if (!acc[categoryID]) {
          acc[categoryID] = [];
        }
        acc[categoryID].push(item);
        return acc;
      }, {});
      setGroupedData(Object.entries(grouped));
    }
  }, [tableData]);



  function formattedDates(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  return (
    <div className={classes.reportBody}>
    {/* <div style={{ marginTop: '1rem' }} /> */}

    <div className={classes.a42}>
        <div className={classes.header}>
            <p style={{ width: '100%',  fontSize: 20, fontWeight: 700, color: "#000"}}>THE REDEEMED CHRISTIAN CHURCH OF GOD</p>
            {/* <p style={{ width: '100%' }}>{company}</p> */}
            {/* <h4>Account Activity Period, 01 - 01 (September - September) 2017/2018</h4> */}
            <h4>FIXED ASSET REPORT AS AT {currentDateTime}</h4>
            <p style={{fontSize:16,fontWeight:'400',color:'black'}}>{allReport ?  'ALL REPORT' :  tableData[0].parish.description}</p>
        </div>
                <table className={classes.table2}>
                    <thead>
                        <tr>
                            <th >ID No.</th>
                            <th >Assets Name</th>
                            <th>Model / Type</th>
                            <th>Serial Number</th>
                            <th>Quantity</th>
                            <th>Amount Purchased</th>
                            <th>Date Purchased</th>
                            <th>Net Book Value</th>
                            {/* <th>Location</th> */}
                            {/* <th>Lifetime in years</th> */}
                            {/* <th>Residual Value</th> */}
                            {/* <th>Date Disposed</th> */}
                            {/* <th>Proceed On Sale</th> */}
                            <th>Remarks</th>
                        </tr>
                    </thead>
                   
                    {groupedData.map(([categoryID, items]) => (
                    <tbody key={categoryID}>
                        <tr style={{  fontWeight: "bold", whiteSpace: "nowrap", marginTop:'50px',}}> {items[0].asset_category.description}</tr>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td style={{width: '50px',}}>{item.identification_number}</td>
                                <td style={{width: "100px", whiteSpace: "nowrap"}}>{item.description}</td>
                                <td style={{width: "100px", whiteSpace: "nowrap", textAlign: "left"}}>{item.model_number}</td>
                                <td style={{width: "50px", whiteSpace: "wrap" }}>{item.serial_number}</td>
                                <td style={{width: "20px", }}>{item.quantity}</td>
                                <td style={{ textAlign: "right" }}>{parseFloat(item.amount_purchased).toLocaleString('en-US', {
                                    minimumIntegerDigits: 1,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}</td>
                                <td>{item.date_purchased}</td>
                                <td>{item.net_book_value}</td>
                                {/* <td>{item.location}</td> */}
                                {/* <td style={{ textAlign: "left" }}>{item.lifetime_in_years}</td> */}
                                {/* <td style={{ textAlign: "left" }}>{item.residual_value}</td> */}
                                {/* <td>{item.date_disposed}</td> */}
                                {/* <td style={{ textAlign: "right" }}>{parseFloat(item.proceed_on_sale === null ? "0.00" : item.proceed_on_sale).toLocaleString('en-US', {
                                    minimumIntegerDigits: 1,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}</td> */}
                                <td>{item.remarks}</td>
                            </tr>
                        ))}
                        
                       <tr className={classes.totalSec}>
                        <td  colSpan="5" >Total</td>
                        <td  style={{textAlign:'right', fontWeight: "bold"}}>
                            {parseFloat(
                                items.reduce((acc, item) => acc + parseFloat(item.amount_purchased), 0)
                            ).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </td>
                        {/* <td colSpan="3" ></td>
                        <td  style={{textAlign:'right'}}>
                            {parseFloat(
                                items.reduce((acc, item) => acc + (parseFloat(item.proceed_on_sale) || 0), 0)
                            ).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </td> */}
                    </tr>
                    </tbody>
                     ))}
                    
                </table>
    </div>
</div>

  )
}