import React, { useState, useEffect } from 'react';
import classes from './PrintBalances.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../api/api';
import { Link, useNavigate, useParams } from 'react-router-dom'

export default function PrintProvinceReport() {
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [totalcDebit, setTotalcDebit] = useState('');
    const [totalcCredit, setTotalcCredit] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');
    const [overallTotal, setOverallTotal] = useState(0);
    const [overallcTotal, setOverallcTotal] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [loads, setLoads] = useState(false);




    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

        const formattedDateTime = `Date Printed: ${day}/${month}/${year} `;

        setCurrentDateTime(formattedDateTime);
    }, []);


    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('companyName');
            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
            const admins = await AsyncStorage.getItem('admin');

            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setCompany(value1);
            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }
            if (admins !== null) {
                setUserType(admins);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const { accounts, selectedEndDate, selectedDate, inputss, ressult } = location.state || {};

    // const totalIncome = item[0].cat_accounts?.reduce((sum, category) => sum + parseFloat(category.total), 0);
    // const totalExpenses = item[1].cat_accounts?.reduce((sum, category) => sum + parseFloat(category.total), 0);



    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const day = date.getDate();
        const daySuffix = (day) => {
            if (day > 3 && day < 21) return 'TH';
            switch (day % 10) {
                case 1: return 'ST';
                case 2: return 'ND';
                case 3: return 'RD';
                default: return 'TH';
            }
        };

        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();

        return `${day}${daySuffix(day)} ${month.toUpperCase()} ${year}`;
    };

    useEffect(() => {
        if (accounts) {
            const firstTotal = accounts[0]?.cat_accounts?.reduce((sum, category) => sum + parseFloat(category.total || 0), 0);
            const secondTotal = accounts[1]?.cat_accounts?.reduce((sum, category) => sum + parseFloat(category.total || 0), 0);
            const firstcTotal = accounts[0]?.cat_accounts?.reduce((sum, category) => sum + parseFloat(category.cTotal || 0), 0);
            const secondcTotal = accounts[1]?.cat_accounts?.reduce((sum, category) => sum + parseFloat(category.cTotal || 0), 0);

            setTotalDebit(firstTotal);
            setTotalCredit(secondTotal);
            setTotalcDebit(firstcTotal);
            setTotalcCredit(secondcTotal);
            setOverallTotal(firstTotal - secondTotal); // Adjust if needed for your calculation logic
            setOverallcTotal(firstcTotal - secondcTotal); // Adjust if needed for your calculation logic
        }
    }, [accounts]);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };

    const fetchProvinceReport = async () => {
        setTableData([]);
        setLoads(true);
        try {
          const response = await axios.get(`${BASE_URL}/continents/drilled-down`, { headers });
        //   const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, { headers });
    
          // console.log(response);
          const provincereport = response.data?.data;
          const regionlist = response.data?.data?.regions;

          setTableData(provincereport);
          console.log(provincereport);
          console.log(regionlist);

        } catch (error) {
          if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            navigate('/login');
          } else {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
              }
            }
            setTableData([]);
          }
        } finally {
          setLoads(false);
        }
      };

      useEffect(() => {
        if (bearer) {
            fetchProvinceReport();   
        }
      }, [bearer]);

    return (
        <div className={classes.a4}>
            <h2 className={classes.headerTop12}>THE REDEEMED CHRISTIAN CHURCH OF GOD</h2>
            <h4 className={classes.headerTwoo12}>{userType}</h4>
            <h4 className={classes.headerTwo12}>PROVINCE REPORT</h4>
            {/* <h4 className={classes.headerTwo}>PROVINCE REPORT {formatDate(selectedDate)} TO {formatDate(selectedEndDate)}</h4> */}
            {(loads) ? (
                              <p>FETCHING REPORT, PLEASE WAIT...</p>
                            ) : (
            <table className={classes.tableDivF}>
                <tbody>
                    {tableData.map((item, index) => {
                        return (
                            <React.Fragment key={index} >
                                <tr>
                                    <th style={{ padding: "15px 0 0 0", fontSize: "18px" }}>{index + 1}. {item.description.toUpperCase()}</th>
                                </tr>
                                {item.regions?.map((regions, tabIndex) => (
                                    <React.Fragment key={tabIndex}>
                                    <tr style={{ padding: "0 0 0 40px" }}>
                                        <th style={{ padding: "5px 0 0 40px", fontSize: "15px" }}>{tabIndex + 1}. {regions.description.toUpperCase()}</th>
                                    </tr>
                                        <React.Fragment >
                                            <table style={{ margin: "0 0 0 80px" }}>
                                            <tr>
                                                <th>S/N</th>
                                                <th>PROVINCE NAME</th>
                                                <th>LAST DATE</th>
                                                <th>CURRENT DATE</th>
                                            </tr>
                                            {regions.provinces?.map((provinces, tabIndex) => (
                                                <tr key={tabIndex}>
                                                    <td style={{whiteSpace: "wrap", width: "20px" }}>{tabIndex + 1}</td>
                                                    <td style={{whiteSpace: "wrap", width: "150px" }}>{provinces.description.toUpperCase()}</td>
                                                    <td style={{whiteSpace: "wrap", width: "70px" }}>{provinces.last_date.toUpperCase()}</td>
                                                    <td style={{whiteSpace: "wrap", width: "70px" }}>{provinces.current_month.toUpperCase()}</td>
                                                </tr>
                                            ))}
                                            </table>
                                        </React.Fragment>
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
                            )}
            {/* <h4 className={classes.btmPrg}>These Financial Statements were approved by the Governing Council at a meeting held on __________________ and were signed on its behalf by:</h4>
            <div className={classes.sigNatures}>
                <span>National Overseer</span>
                <span>National Treasurer</span>
            </div> */}
        </div>
    )
}
