import React, { useState, useEffect } from "react";
import classes from "./Expenses.module.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Button, Modal, Form, Row, Col } from "react-bootstrap";
// import Folder from '../../Images/folder-2.svg';
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
// import favicon from '../../Images/faviconn.png'
import { useNavigate } from "react-router-dom";
// import { Link, useNavigate, useParams } from 'react-router-dom'
// import Table from 'react-bootstrap/Table';
import { BASE_URL } from "../api/api";
// import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";
import LatestDash from "../LatestDashboard/LatestDash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import MobileMenu from "../../Component/Mobile/MobileNav";
import { toast } from "react-toastify";

export default function PettyCash() {
   const [currLoading, setCurrLoading] = useState(false);
  const [teller, setTeller] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [currency, setCurrency] = useState([]);
  const [selectedDebitAccount, setSelectedDebitAccount] = useState("");
  const [incomeLoading, setIncomeLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [subCat1, setSubcat1] = useState([]);
  const [formData, setFormData] = useState([
    { sn: 1, accountName: "", accountCode: "", amount: "" },
  ]);
  const [incomeAmount, setIncomeAmount] = useState();
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [payment, setPayment] = useState("");
  const [description, setDescription] = useState("");
  const [loanLoading, setLoanLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [bearer, setBearer] = useState("");
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableDatas, setTableDatas] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [subCat, setSubcat] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [ledgTableData, setLedgTableData] = useState([]);
  const [totalDebit, setTotalDebit] = useState("");
  const [totalCredit, setTotalCredit] = useState("");
  const [totalReceipts, setTotalReceipts] = useState("");
  // const [totalPages, setTotalPages] = useState(1);
  // const [totalEntries, setTotalEntries] = useState("");
  const [continent, setContinent] = useState("");
  const [region, setRegion] = useState("");
  const [province, setProvince] = useState("");
  const [userType, setUserType] = useState("");
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [userTypes, setUserTypes] = useState("");
  const [admin, setAdmin] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [provinceID, setProvinceID] = useState("");
  const [openNav, setOpenNav] = useState(false);

  //   const [province, setProvince] = useState('');
  //   const [continent, setContinent] = useState('');

  // const filteredData = accounts.filter(item => item.details.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  // const fetchBankss = async () => {
  //     setIsLoading(true);
  //     try {
  //         const response = await axios.get(`${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`, { headers });
  //         const results = response.data?.data;

  //         setTableData(results);
  //         // console.log(results);
  //     } catch (error) {
  //         if (error.response && error.response.status === 401) {

  //             navigate('/login');
  //         } else {
  //             const errorStatus = error.response?.data?.message;
  //             console.log(errorStatus);
  //             setTableData([]);
  //         }
  //     } finally {
  //         setIsLoading(false);
  //     }
  // };

  const fetchExpenses = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/fetch-all-expenses?page=${currentPage}`,
        { headers }
      );

      const results = response.data?.data?.data;
      const resultx = response.data?.data?.total;
      setTotalEntries(resultx);
      setTableData(results);
      console.log(results);
      const total = response.data?.data?.last_page || 1;
      setTotalPages(total);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchExpenses();
      //   fetchTotalExpenses();
    }
  }, [bearer, currentPage]);

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (accounts) {
      const debitTotal = accounts.reduce(
        (total, item) => total + parseFloat(item.debit),
        0
      );
      const creditTotal = accounts.reduce(
        (total, item) => total + parseFloat(item.credit),
        0
      );

      // Format the numbers with commas and two decimal places
      const formattedDebitTotal = debitTotal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const formattedCreditTotal = creditTotal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
    }
  }, [accounts]);

  const handleBank = (selectedOption) => {
    setSelectedBank(selectedOption.value);
  };

  console.log(selectedBank);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      const value2 = await AsyncStorage.getItem("admin");
      const admins = await AsyncStorage.getItem("admin");

      const continentName = await AsyncStorage.getItem("continent");
      const regionName = await AsyncStorage.getItem("region");
      const provinceName = await AsyncStorage.getItem("province");
      const current = await AsyncStorage.getItem("currentMonth");

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setAdmin(value2);
      }

      if (admins !== null) {
        setUserTypes(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (current !== null) {
        console.log(current);
        const date = new Date(current);

        setCurrentMonth(date);

        const startOfDateRange = new Date(1900, 0, 1);

        const endOfDateRange = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        );

        // Format date function as 'YYYY-MM-DD'
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };

        setMinDate(formatDate(startOfDateRange));
        setMaxDate(formatDate(endOfDateRange));

        console.log(
          "Date Range:",
          formatDate(startOfDateRange),
          "to",
          formatDate(endOfDateRange)
        );
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(
      date.getMinutes()
    )} ${date.getHours() >= 12 ? "PM" : "AM"}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(
      date.getMinutes()
    )} ${date.getHours() >= 12 ? "PM" : "AM"}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  //   const filteredData = ledgTableData.filter(item => {
  //     const searchFields = [item.narration, item.transaction_date, formatDate(item.created_at)];
  //     return searchFields.some(field => field.toLowerCase().includes(searchTerm.toLowerCase()));
  //   });

  const handleCreate = () => {
    navigate("/payment/create_petty_cash");
  };

  // const filteredData = tableData.filter(item => item?.description.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  // const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  // const formattedTotalEntries = totalEntries.toLocaleString();

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");

  const fetchExp = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}/account/expenses`, {
        headers,
      });
      const results = response.data?.data;
      const oopt = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));
      setSubcat(oopt);
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExp();
  }, [bearer]);

  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this expenses.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/delete-expenses?id=${id}`, {
        headers,
      });
      fetchExpenses();
      Swal.fire({
        icon: "success",
        title: "Successful",
        text: response.data.message,
      });
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: JSON.stringify(error.response.data.message),
        });
        console.log(errorMessage);
      }
    }
  };

  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };

  const addRow = () => {
    const newRow = {
      sn: formData.length + 1,
      accountName: "",
      accountCode: "",
      amount: "",
    };
    setFormData([...formData, newRow]);
    // setSelectedPayment('');
  };

  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption.value);

  };

  const handleAccountChange = (selectedOption, index) => {
    const selectedAccount = selectedOption.value;
    const selectedItem = subCat1.find((item) => item.id === selectedAccount);
    // const intselectedId = parseInt(selectedAccount);
    const selectedCode = selectedItem?.gl_code;
    // const selectedGlCode = subCat.find((item) => item.id === intselectedId) || '';

    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      accountName: selectedAccount,
      accountCode: selectedCode,
    };

    setFormData(updatedFormData);
  };

  const handleValueChange = (value) => {
    setIncomeAmount(value);
  };

  const handleValueChange1 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      amount: value,
    };
    setFormData(updatedFormData);
    calculateTotalCredit(); // Recalculate total credit whenever table amounts change
  };

  const calculateTotalCredit = () => {
    const total = formData.reduce(
      (acc, item) => acc + parseFloat(item.amount || 0),
      0
    );
    const formattedTotal = total.toFixed(2);
    setTotalCredit(formattedTotal);
  };

  useEffect(() => {
    calculateTotalCredit();
  }, [formData]);

  const createIncome = async () => {
    setIncomeLoading(true);

    try {
      const accountNames = formData
        .map((row) => row.accountName)
        .filter((name) => name !== undefined);
      const amounts = formData
        .map((row) => row.amount)
        .filter((amount) => amount !== undefined);
      const payload = {
        account_id: accountNames,
        gl_code: selectedDebitAccount,
        description: description,
        payment_mode: selectedPayment,
        breakdown_amount: amounts,
        total_amount: totalCredit,
        teller_no: teller,
        particulars: payment,
        currency: selectedCurrency,
        transaction_date: selectedDate,
        // bank_id: selectedBanks
      };

      console.log("Payload:", payload);

      const response = await axios.post(`${BASE_URL}/post-expenses`, payload, {
        headers,
      });

      console.log(response.data, "Response Data");
      navigate(-1);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
          toast.error(errorMessage);
        }
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: errorMessage,
        });
      }
    } finally {
      setIncomeLoading(false);
    }
  };

  const fetchSubCat = async () => {
    setLoading(true);

    try {
        const response = await axios.get(`${BASE_URL}/account/expenses`, { headers });
        //     {

        //       headers: { 
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${bearer}`
        //       }
        //     }
        //   );
        const results = response.data?.data;
        const oopt = results.map((item) => ({
            label: item.gl_name,
            value: item.id,
            code: item.gl_code,


        }));
        setSubcat(oopt);
        setSubcat1(results);

        //   console.log(results, "Accounts");
    } catch (error) {
        const errorStatus = error.response.data.message;
        console.error(errorStatus);
    } finally {
        setLoading(false);
    }
};

useEffect(() => {
        if (bearer) {
            fetchSubCat();
        }
    }, [bearer]);

  const fetchCurrency = async () => {
    setCurrLoading(true);
    try {
        const response = await axios.get(`${BASE_URL}/fetch-all-currencies`, { headers });
        const results = response.data?.data;
        // console.log(results, "currencis")
        const options = results.map((item) => ({
            label: item.code,
            value: item.id,
            symbol: item.symbol
        }));
        setCurrency(results);
        setSelectOptions(options);
    } catch (error) {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setCurrency([]);
    } finally {
        setCurrLoading(false);
    }
};

const fetchSubBanks = async () => {
  setLoading(true);

  try {
      const response = await axios.get(`${BASE_URL}/get-banks`, { headers });
      //     {

      //       headers: { 
      //         'Content-Type': 'application/json',
      //         'Authorization': `Bearer ${bearer}`
      //       }
      //     }
      //   );
      const banked = response.data?.data;
      const ban1 = banked.map((item) => ({
          label: item.bank_name,
          value: item.id,


      }));
      setBanks(ban1);
      //   console.log(response);
  } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
  } finally {
      setLoading(false);
  }
};

  useEffect(() => {
        if (bearer) {
            fetchCurrency();
            fetchSubBanks();
        }
    }, [bearer]);

  return (
    <div>
      {openNav && <MobileMenu setOpenNav={setOpenNav} openNav={openNav} />}

      <LatestDash setOpenNav={setOpenNav} openNav={openNav} />
      <div>
        <Modal size='xl' show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Create Petty Cash</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body" style={{ border: "none" }}>
              <div className="row">
                <div
                  className={`${classes.formSection} ${classes.formSectionGap}`}
                >
                 <Row>
          
          <Col md={6}>
            <Form.Group controlId="payment-to">
              <Form.Label className="font-weight-400">Payment To</Form.Label>
              <Form.Control
                type="text"
                required
                value={payment}
                onChange={(e) => setPayment(e.target.value)}
                name="name"
              />
            </Form.Group>
          </Col>

          {/* Transaction Date Section */}
          <Col md={6}>
            <Form.Group controlId="transaction-date">
              <Form.Label className="font-weight-400">Transaction Date</Form.Label>
              <Form.Control
                type="date"
                required
                onChange={handleDateChange}
                min={minDate}
                max={maxDate}
                value={selectedDate}
                name="date"
              />
            </Form.Group>
          </Col>
        </Row>
                </div>

                <div
                  className="col-md-6"
                  style={{ width: "95%", marginLeft: 22, marginTop: -40 }}
                >
                  <div className="form-group row" style={{}}>
                    <label
                      for="example-text-input"
                      className="col-sm-2 col-form-label font-weight-400"
                      style={{ textAlign: "left" }}
                    >
                      Description
                    </label>
                    <div className="col-sm-12">
                      <textarea
                        style={{ maxWidth: "100%" }}
                        className="form-control"
                        rows="2"
                        cols="10"
                        required=""
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        name="description"
                      />
                    </div>
                  </div>
                </div>
                {/* <div style={{ marginTop: "5px" }} /> */}
                <Row style={{marginLeft: 10,}}>
          {/* Currency Section */}
          <Col md={6}>
            <Form.Group controlId="currency">
              <Form.Label className="font-weight-400">Currency</Form.Label>
              <Select
                onChange={(selectedOption) => handleCurrencyChange(selectedOption)}
                options={selectOptions}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  menu: (provided) => ({
                    ...provided,
                  }),
                }}
              />
            </Form.Group>
          </Col>

          {/* Amount Section */}
          <Col md={6}>
            <Form.Group controlId="amount">
              <Form.Label className="font-weight-400">Amount</Form.Label>
              <CurrencyInput
                id="amount"
                name="amount"
                decimalsLimit={2}
                className="form-control"
                value={incomeAmount}
                onValueChange={handleValueChange}
                style={{
                  textAlign: 'right',
                  border: '1px solid #e4e4e4',
                }}
              />
            </Form.Group>
          </Col>
        </Row>

                <div style={{ marginTop: 40 }} />

                <div className="row" style={{ width: "100%", marginLeft: 20 }}>
                  <div className="table-responsive">
                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                      <thead
                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        <tr>
                          <th>#</th>
                          <th style={{ width: "500px" }}>Account Name</th>
                          <th style={{ width: "200px" }}>Account Code</th>
                          <th style={{ width: "200px" }}>Amount</th>
                          <th>
                            <div style={{}}>
                              <div
                                className="btn btn-sm printbtninv"
                                onClick={() => addRow()}
                              >
                                <i
                                  className="fas fa-plus"
                                  style={{
                                    color: "#17a2b8",
                                    backgroundColor: "#afe1e9",
                                    padding: 2,
                                    borderColor: "#b0d1d6",
                                    borderRadius: 5,
                                    fontSize: 12,
                                  }}
                                ></i>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        {formData.map((row, index) => (
                          <tr key={index}>
                            <td>{row.sn}</td>
                            <td>
                              <Select
                                //   value={row.accountName}
                                onChange={(selectedOption) =>
                                  handleAccountChange(selectedOption, index)
                                }
                                options={subCat}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    maxHeight: "300px",
                                    // maxWidth: '200px',
                                    overflowY: "auto",
                                  }),
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={row.accountCode}
                                disabled
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                                // decimalsLimit={2}
                                value={row.amount}
                                className="form-control"
                                onValueChange={(value) =>
                                  handleValueChange1(value, index)
                                }
                                style={{
                                  textAlign: "right",
                                  border: "none",
                                }}
                              />
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                width: "2rem",
                              }}
                            >
                              <div
                                className="btn btn-danger-soft btn-sm"
                                onClick={() => deleteRow(index)}
                              >
                                <i
                                  className="far fa-trash-alt"
                                  style={{
                                    color: "#dc3545",
                                    backgroundColor: "#dc35451a",
                                    padding: 2,
                                    borderColor: "#dc35454d",
                                    borderRadius: 5,
                                    fontSize: 12,
                                  }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div style={{ marginTop: 30 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "20px",
                      }}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>Total Debit:</Form.Label>
                        <CurrencyInput
                          //
                          name="amount"
                          decimalsLimit={2}
                          className="form-control"
                          value={incomeAmount} // Set the value to the balance state
                          disabled
                          style={{
                            textAlign: "right",
                            border: "1px solid #e4e4e4",
                            backgroundColor: "none",
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Total Credit</Form.Label>
                        <CurrencyInput
                          //
                          name="amount"
                          decimalsLimit={2}
                          className="form-control"
                          value={totalCredit} // Set the value to the balance state
                          disabled
                          style={{
                            textAlign: "right",
                            border: "1px solid #e4e4e4",
                            backgroundColor: "none",
                          }}
                        />
                      </Form.Group>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlay"></div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Go back
            </Button>
            {/* createAccount */}
            <Button variant="success" onClick={createIncome}>
              {incomeLoading ? (
                <>
                  <Spinner size="sm" />
                  <span style={{ marginLeft: "5px" }}>
                    Creating account, Please wait...
                  </span>
                </>
              ) : (
                "Create Payments"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className={classes.finishedbodyCont}>
        {/* <div className={classes.topPadding}>
          <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3>Sales Invoice</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <h3 style={{ color: '#2D995F' }}>{user.toLocaleUpperCase()}</h3>
            </div>
          </div>
        </div> */}

        <h3 className={classes.finishedbodyhead}>Petty Cash</h3>
        <p style={{ margin: " 0 10px 20px 15px" }}>
          Create, View, Edit, Delete, and Print your petty cash
        </p>
        {/* <h3 className={classes.productText1}>Petty Cash</h3> */}
        <div className={classes.mainform}>
          <div className={`row ${classes.awrow} ${classes.margintopLeft}`}>
            <div className="col-md-4">
              <div className="form-group row">
                <label
                  htmlFor="example-text-input"
                  className={`col-sm-5 col-form-label font-weight-400 ${classes.labelLabel}`}
                >
                  Accounts:
                </label>
                <div className={`col-sm-9  ${classes.lblrow}`}>
                  <Select
                    value={selectedCustomer}
                    onChange={setSelectedCustomer}
                    options={subCat}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (provided) => ({
                        ...provided,
                        maxHeight: "300px",
                        maxWidth: "300px",
                        overflowY: "auto",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={`col-md-4 ${classes.marginLeft100}`}>
              <div className="form-group row">
                <label
                  htmlFor="example-text-input"
                  className={`col-sm-5 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2} ${classes.labelLabel}`}
                >
                  Start Date:
                </label>
                <div className="col-sm-8">
                  <input
                    className={`form-control  ${classes.lblroww}`}
                    required=""
                    type="date"
                    onChange={handleDateChange}
                    name="start"
                    value={selectedDate}
                    min={minDate}
                    max={maxDate}
                  />
                </div>
              </div>
            </div>
            <div className={`col-md-4 ${classes.marginLeft125}`}>
              <div className="form-group row">
                <label
                  htmlFor="example-text-input"
                  className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2} ${classes.labelLabel}`}
                >
                  End Date:
                </label>
                <div className={`col-sm-8  ${classes.lblrow}`}>
                  <input
                    className={`form-control  ${classes.lblroww}`}
                    required=""
                    type="date"
                    onChange={handleDateChange1}
                    name="end"
                    value={selectedEndDate}
                    min={minDate}
                    max={maxDate}
                  />
                </div>
              </div>
            </div>
            {/* <div class={`modal-footer ${classes.mobileResShit}`} >
                        <div className={`modal-footer ${classes.overlayBtnrow}`}> */}
            <OverlayTrigger
              placement="top"
              className={classes.btndf1}
              overlay={
                <Tooltip id="button-tooltip" className={classes.btndf1}>
                  Click here to process personal ledger between the selected
                  dates
                </Tooltip>
              }
            >
              <Button
                variant="success"
                style={{
                  width: 100,
                  height: 35,
                  marginTop: 35,
                  marginLeft: -120,
                }}
                disabled={
                  !selectedEndDate || !selectedCustomer || !selectedDate
                }
                className={classes.overlayBtns11}
              >
                {fetchingLoading ? (
                  <>
                    <Spinner size="sm" />
                    <span style={{ marginLeft: "5px" }}>Loading</span>
                  </>
                ) : (
                  "Process"
                )}
              </Button>
            </OverlayTrigger>
            {/* <div className={classes.formIntBtn}> */}
            <OverlayTrigger
              placement="top"
              className={classes.btndf1}
              overlay={
                <Tooltip id="button-tooltip" className={classes.btndf1}>
                  Click here to create new petty cash
                </Tooltip>
              }
            >
              <Button
                style={{
                  width: 100,
                  height: 35,
                  marginTop: 35,
                  marginLeft: 20,
                  whiteSpace: "nowrap",
                }}
                className={classes.btn1}
                variant="success"
                onClick={handleShow}
              >
                Create New
              </Button>
            </OverlayTrigger>
            {/* </div> */}
          </div>
          {/* </div> */}
          {/* )} */}
          {/* </div> */}
          <div className={classes.loandgrantcards}>
            <div className={classes.loandethead}></div>

            <div
              className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}
            >
              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                <div className={classes.actionsBtns}>
                  <Button variant="success">Copy</Button>
                  <Button variant="success">Excel</Button>
                  <Button variant="success">PDF</Button>
                  <Button variant="success">Column visibility</Button>
                </div>
                <div className={classes.show}>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select
                      name="DataTables_Table_0_length"
                      aria-controls="DataTables_Table_0"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      //  value={entriesPerPage}
                      //     onChange={(e) => {
                      //     setEntriesPerPage(parseInt(e.target.value));
                      //     setCurrentPage(1);
                      //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div
                  id="DataTables_Table_0_filter"
                  className="dataTables_filter"
                >
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
                      type="search"
                      value={searchTerm}
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
              {isLoading ? (
                <p>Fetching data...</p>
              ) : (
                <div className="table-responsive">
                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                    <thead style={{ whiteSpace: "nowrap" }}>
                      <tr>
                        <th>Transaction Date</th>
                        <th>Particulars</th>
                        <th>Description</th>
                        {/* <th>Receipt Number</th> */}
                        <th>Amount</th>
                        <th>Payment Mode</th>
                        <th>Teller Number</th>
                        <th>Received By</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: "nowrap" }}>
                      {tableData.map((item, index) => (
                        <tr key={index}>
                          {/* <td>
                                    <input
                type="checkbox"
                checked={checkedRows[index] || false}
                onChange={() => handleCheckboxChange(index)}
              />
    </td> */}
                          <td>{item.transaction_date}</td>
                          <td>{item.particular}</td>
                          <td style={{ whiteSpace: "wrap" }}>
                            {item.description}
                          </td>
                          {/* <td>{item.voucher_number}</td> */}
                          <td style={{ textAlign: "right" }}>
                            {parseFloat(item.amount).toLocaleString("en-US", {
                              minimumIntegerDigits: 1,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>{item.mode?.name}</td>
                          <td>{item.teller_number}</td>
                          <td>{item.user?.name}</td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              className={classes.btndf1}
                              overlay={
                                <Tooltip
                                  id="button-tooltip"
                                  className={classes.btndf1}
                                >
                                  Click to view Petty Cash
                                </Tooltip>
                              }
                            >
                              <div className="mr-1 btn btn-success-soft btn-sm">
                                <i
                                  className="far fa-eye"
                                  style={{
                                    color: "#008a4b",
                                    backgroundColor: "#28a7451a",
                                    padding: 2,
                                    borderColor: "#28a7454d",
                                    borderRadius: 5,
                                    fontSize: 12,
                                  }}
                                ></i>
                                {/* onClick={() => handleEyeClick(item.id)}  */}
                              </div>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              className={classes.btndf1}
                              overlay={
                                <Tooltip
                                  id="button-tooltip"
                                  className={classes.btndf1}
                                >
                                  Click to delete row
                                </Tooltip>
                              }
                            >
                              <div
                                onClick={() => handleTrashClick(item.id)}
                                className="btn btn-danger-soft btn-sm"
                              >
                                <i
                                  className="far fa-trash-alt"
                                  style={{
                                    color: "#dc3545",
                                    backgroundColor: "#dc35451a",
                                    padding: 2,
                                    borderColor: "#dc35454d",
                                    borderRadius: 5,
                                    fontSize: 12,
                                  }}
                                ></i>
                              </div>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              className={classes.btndf1}
                              overlay={
                                <Tooltip
                                  id="button-tooltip"
                                  className={classes.btndf1}
                                >
                                  Click to print row
                                </Tooltip>
                              }
                            >
                              <div className="btn btn-success-soft btn-sm">
                                <i
                                  className="fa fa-print dawg"
                                  style={{
                                    color: "#008a4b",
                                    backgroundColor: "#28a7451a",
                                    padding: 2,
                                    borderColor: "#28a7454d",
                                    borderRadius: 5,
                                    fontSize: 12,
                                  }}
                                ></i>
                                {/* onClick={() => handlePrintInvoice(item.id)} */}
                              </div>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className={classes.endded}>
              <p>
                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
              </p>
              <div style={{ display: "flex" }}>
                <button
                  style={{
                    border: "none",
                    backgroundColor: "gainsboro",
                    borderRadius: 3,
                    height: "2.5rem",
                    width: "100px",
                    fontWeight: 500,
                    fontSize: 14,
                    padding: "0.5rem",
                    fontFamily: "nunito",
                    color: "#000",
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, page) => {
                  // Show only 5 pages or less if available
                  if (
                    page < 5 ||
                    page === currentPage - 1 ||
                    page === totalPages - 1
                  ) {
                    return (
                      <button
                        key={page + 1}
                        style={{
                          marginLeft: "0.4rem",
                          marginRight: "0.4rem",
                          fontSize: "14px",
                          fontFamily: "nunito",
                          fontWeight: 400,
                          color: page + 1 === currentPage ? "#ffffff" : "#000",
                          backgroundColor:
                            page + 1 === currentPage ? "#28a745" : "gainsboro",
                          height: "2.5rem",
                          borderRadius: "89px",
                          padding: "0.5rem",
                          border: "none",
                          width: "40px",
                          cursor: "pointer",
                        }}
                        onClick={() => setCurrentPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  style={{
                    cursor: "pointer",
                    border: "none",
                    backgroundColor: "gainsboro",
                    borderRadius: 3,
                    height: "2.5rem",
                    width: "100px",
                    fontWeight: 500,
                    fontSize: 14,
                    padding: "0.5rem",
                    fontFamily: "nunito",
                    color: "#000",
                    marginLeft: 10,
                  }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
