import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Icon1 from '../../assets/nw/Group 2.png'
import Icon2 from '../../assets/nw/Group 3.png'
import Icon3 from '../../assets/nw/Group 4.png'
import Icon5 from '../../assets/nw/Group 5.png'
import Icon6 from '../../assets/nw/Group 6.png'
import Icon7 from '../../assets/nw/Group 7.png'
import Icon8 from '../../assets/nw/Group 8.png'
import Icon9 from '../../assets/nw/Group 9.png'
import classes from "./LatestFixedMenu.module.css"

// import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Button, ButtonGroup, Form, Modal, Spinner } from 'react-bootstrap';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BASE_URL } from '../api/api';
import Select from 'react-select';

export default function LatestFixedMenu2() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [createLoading, setCreateLoading] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [bearer, setBearer] = useState('');
  const [admin, setAdmin] = useState('');
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [subCat, setSubcat] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [navOpen, setNavOpen] = useState(false);




  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleNav = () => setNavOpen(!navOpen);

  const navToDashboard = () => {
    navigate('/fixed_asset_home')
  }
  const navToDisposed = () => {
    navigate('/fixed_asset_home')
  }
  const navZone = () => {
    navigate('/fixed_asset/zone')
  }
  const navArea = () => {
    navigate('/fixed_asset/area')
  }
  const navParish = () => {
    navigate('/fixed_asset/parish')
  }
  const navFixed = () => {
    navigate('/fixed_asset_report')
  }
  



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }


    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const uploadStatement = async () => {
    setCreateLoading(true);

    try {

      const formData = new FormData();
      formData.append('date', selectedDate);
      formData.append('file', selectedImage[0]);

      const response = await axios.post(
        `${BASE_URL}/quotes/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response.data?.message, "heeee");
      handleClose();
      // Reset form fields
      setSelectedImage("");
      setSelectedDate("");
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
      }
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const fetchSubCat = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const results = response.data?.data;
      setSubcat(results);

      //   console.log(results, "NIYIN");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const opt = subCat.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));

  // useEffect(() => {
  //   if (bearer) {
  //     fetchSubCat();
  //   }
  // }, [bearer]);

  return (
    <div>
      <div className={classes.navSection1}>
        <div className={classes.hamburger} onClick={toggleNav}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {/* <div className={classes.navSectionHead}>
                <span>
                    <small>Welcome</small>
                    <h4>Eniola Lawanson</h4>
                </span>
                <span>
                    <h4>Dashboard</h4>
                </span>
            </div> */}

        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Statement of Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ marginTop: 20 }}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  // autoFocus
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
                <div style={{ marginTop: 10 }} />
                <Form.Label className={classes.formLabelCustom}>Bank</Form.Label>
                <Select
                  className={classes.formControl}
                  options={opt}
                  placeholder="Select Bank"
                  onChange={handleAccountChange}
                  menuPortalTarget={document.body}
                  styles={{
                    width: "100%",
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '100px',
                      overflowY: 'auto',

                    }),
                  }}
                />


                <div style={{ marginTop: 10 }} />
                <Form.Label>File</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Choose file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={handleImageChange}

                />






              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Go back
            </Button>
            <Button variant="success" onClick={uploadStatement}>
              {createLoading ? (
                <>
                  <Spinner size='sm' />
                  <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                </>
              ) : (
                "Upload"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
        <div className={`$classes.navSectionNavCont1} ${navOpen ? 'active' : ''}`}>
          <div className={classes.navSectionNav}>
          <div onClick={navToDashboard} className={classes.menuSect}>
              <img  src={Icon6} alt="Icon" className={classes.iconStyle} />
              <p>Dashboard</p>
            </div>
           
            <div onClick={navZone} className={classes.menuSect}>
              <img src={Icon5} alt="Icon" className={classes.iconStyle} />
              <p>Manage Zones</p>
            </div>
            <div onClick={navArea} className={classes.menuSect}>
              <img src={Icon1} alt="Icon" className={classes.iconStyle} />
              <p>Manage Areas</p>
            </div>
            <div onClick={navParish} className={classes.menuSect}>
              <img src={Icon3} alt="Icon" className={classes.iconStyle} />
              <p>Manage Parish</p>
            </div>
            <div onClick={navFixed} className={classes.menuSect}>
              <img src={Icon9} alt="Icon" className={classes.iconStyle} />
              <p>Report</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
