import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';

// import classes from './ManageUser.module.css';
import classes from './PrintCheque.module.css'

import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentDash from '../../Payment Dashboard/PaymentDash';
import { ToWords } from 'to-words';
import { BASE_URL } from '../../api/api';


export default function PrintCheque() {
    const navigate = useNavigate();
    const [banks, setBanks] = useState([]);
    const [bankLoading, setBankLoading] = useState(false);
    const [chequeLoading, setChequeLoading] = useState(false);
    const [selectedBank, setSelectedBank] = useState('');
    const [bearer, setBearer] = useState('');
    const [description, setDescription] = useState('');
    const [beneficiary1, setBeneficiary1] = useState('');
    const [beneficiary, setBeneficiary] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [totalNetPayInWords, setTotalNetPayInWords] = useState('');
    const location = useLocation();
    const selectedData = location.state || {};
    const toWords = new ToWords({
        localeCode: 'en-US',
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: {
          name: 'Naira',
          plural: 'Naira',
        //   name: vouchers?.vouchers[0]?.currency?.currency,
        //   plural: vouchers?.vouchers[0]?.currency?.currency,
        //   symbol: vouchers?.vouchers[0]?.currency?.symbol,
          fractionalUnit: {
            name: 'Kobo',
            plural: 'Kobo',
            // symbol: '',
          },
        }
      }
      });


   


    const convertToWords = (number) => {
        // Check if number is not a valid number
        if (isNaN(number) || number === '' || number === null) {
            return 'Invalid number';
        }

        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = [
            '',
            'Eleven',
            'Twelve',
            'Thirteen',
            'Fourteen',
            'Fifteen',
            'Sixteen',
            'Seventeen',
            'Eighteen',
            'Nineteen',
        ];
        const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
        const suffixes = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
        const numToWords = (num) => {
            if (num === 0) return '';
            if (num < 10) return units[num];
            if (num < 20) return teens[num - 10];
            if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
            if (num < 1000)
                return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' ' + numToWords(num % 100) : '');
            for (let i = 1; i <= 4; i++) {
                if (num < Math.pow(1000, i + 1)) {
                    return numToWords(Math.floor(num / Math.pow(1000, i))) + ' ' + suffixes[i] +
                        (num % Math.pow(1000, i) !== 0 ? ' ' + numToWords(num % Math.pow(1000, i)) : '');
                }
            }
            return 'Number too large to convert';
        };

        const [integerPart, decimalPart] = number.toFixed(2).split('.');
        const integerWords = parseInt(integerPart, 10) !== 0 ? numToWords(parseInt(integerPart, 10)) + ' Naira' : '';
        const decimalWords = parseInt(decimalPart, 10) !== 0 ? ' ' + numToWords(parseInt(decimalPart, 10)) + ' Kobo' : '';

        const result = (integerWords + (integerWords && decimalWords ? ',' : '') + decimalWords).trim();

        return result;
    };

console.log(selectedData, "here");


    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');

            if (value !== null) {
                setBearer(value);
                // setAuthenticated(true);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const handleGoBack = () => {
        navigate(-1);
    }

    const handleCheque = async () => {
        setChequeLoading(true);

        console.log(selectedData.id, selectedData.selectedBank);
     
        try {
          const response = await axios.post(`${BASE_URL}/pay-payables`,
            {
             
                payable_id: [selectedData.id],
                payment_bank: selectedData.selectedBank
            
            },
            { headers }
          );
          window.print();
    
    
        //  toast.success(response.data.message);
         
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
            //   toast.error(errorMessage)
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: errorMessage,
              });
              console.log(errorMessage);
          }
        } finally {
          setChequeLoading(false);
        }
      };

    

   


    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    const dayString = day.toString().padStart(2, '0');
    const monthString = month.toString().padStart(2, '0');
    const yearString = year.toString();

    const day1 = dayString.slice(0, 1);
    const day2 = dayString.slice(1);

    const month1 = monthString.slice(0, 1);
    const month2 = monthString.slice(1);

    const year1 = yearString.slice(0, 1);
    const year2 = yearString.slice(1, 2);
    const year3 = yearString.slice(2, 3);
    const year4 = yearString.slice(3);
    // Now you can use day1, day2, month1, month2, year1, year2 in your JSX

    const supplierName = selectedData.supplier?.name.toUpperCase();
    const customerName = selectedData.customer?.name.toUpperCase();
    const sum = selectedData.amount;
 

    return (
        <div className={classes.topMargin}>
                                <div className="content-header row align-items-center m-0">

                                    <div className={classes.printCheque}>

                                        <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                                            
                                            <div
                                                style={{
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                    justifyContent: "flex-end",
                                                    display: "flex",
                                                    marginLeft: "auto",
                                                    gap: 20
                                                }}
                                            >
                                                <Button variant='danger' onClick={handleGoBack} >Cancel</Button>
                                                <Button variant="success" onClick={handleCheque}>
                                                    {chequeLoading ? (
                                                        <>
                                                            <Spinner size='sm' />
                                                            <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                                                        </>
                                                    ) : (
                                                        "Print Cheque"
                                                    )}
                                                </Button>
                                            </div>

                                        </nav>
                                    </div>
                                </div>
                                <div className="row">
                                   
                                   
                                    <div className={classes.card}>
                                       

                                        <div className={classes.Cheque}>
                                            <div className={classes.chequeHeader}>
                                                <div style={{ marginBottom: '1cm' }} className={classes.printHide}>
                                                    <h6 style={{ fontSize: '10.72px', margin: '0', visibility: 'hidden' }}>302, HERBERT MACAULAY WAY. SABO YABA, LAGOS</h6>
                                                </div>
                                                <div className={classes.printHide} style={{ marginLeft: '2cm' }}>
                                                    <h2>ABC Bank</h2>
                                                    <p style={{ visibility: "hidden" }}>The bank of African</p>
                                                </div>
                                                <div className={classes.dateSection} >
                                                    <div className={`${classes.refNO} ${classes.printRemove}`}>
                                                        <h5>48983408377</h5>
                                                        <h6> u9ur909ru9e9qu05459</h6>
                                                    </div>
                                                    <span >
                                                        <div className={classes.dateInput}>
                                                            <p className={`${classes.label} ${classes.printHide}`}>Day</p>
                                                            <div className={classes.inputContainer}>
                                                                <p className={classes.input}>{day1}</p>
                                                                <p className={classes.input}>{day2}</p>
                                                            </div>
                                                        </div>
                                                        <p className={`${classes.dash} ${classes.printHide}`}>-</p>
                                                        <div className={classes.dateInput}>
                                                            <p className={`${classes.label} ${classes.printHide}`}>Month</p>
                                                            <div className={classes.inputContainer}>
                                                                <p className={classes.input}>{month1}</p>
                                                                <p className={classes.input}>{month2}</p>
                                                            </div>
                                                        </div>
                                                        <p className={`${classes.dash} ${classes.printHide}`}>-</p>
                                                        <div className={classes.dateInput}>
                                                            <p className={`${classes.label} ${classes.printHide}`}>Year</p>
                                                            <div className={classes.inputContainer}>
                                                                <p className={classes.input}>{year1}</p>
                                                                <p className={classes.input}>{year2}</p>
                                                                <p className={classes.input}>{year3}</p>
                                                                <p className={classes.input}>{year4}</p>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={classes.paydetails}>
                                                <div className={classes.details}>
                                                    <p className={`${classes.label} ${classes.printHide}`}>Pay to</p>
                                                    <div className={classes.paymentTo}>{supplierName || customerName}</div>
                                                </div>
                                                <div className={classes.details}>
                                                    <p className={`${classes.label} ${classes.printHide}`}>The sum of</p>
                                                    <div className={classes.amount} > {sum ? toWords.convert(parseFloat(sum)) : ''}</div>
                                                </div>

                                            </div>
                                            <div className={classes.nairaInput}>
                                                <p for="" style={{ fontSize: '20px' }} className={classes.printHide}>₦</p>
                                                <div className={classes.amtInFig}> {parseFloat(sum).toLocaleString('en-US', {
                                                    minimumIntegerDigits: 1,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}</div>
                                            </div>
                                        </div>

                                    </div>
                                 
                                </div>
                          
                            
                     
                   



           
        </div>
    )
}
