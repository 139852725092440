import React, { useState, useEffect } from "react";
import classes from "./PrintFixed.module.css";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { useLocation } from "react-router-dom";

export default function PrintFixedAssets() {
  const [bearer, setBearer] = useState("");
  const [company, setCompany] = useState("");
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [totalDebit, setTotalDebit] = useState("");
  const [totalCredit, setTotalCredit] = useState("");
  const [continent, setContinent] = useState("");
  const [region, setRegion] = useState("");
  const [province, setProvince] = useState("");
  const [userType, setUserType] = useState("");
  const [groupedData, setGroupedData] = useState([]);
  const { data } = location.state || [];
  console.log(data);

  useEffect(() => {
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = addLeadingZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    const minutes = addLeadingZero(currentDate.getMinutes());
    const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
    const seconds = addLeadingZero(currentDate.getSeconds());
    const ampm = currentDate.getHours() >= 12 ? "PM" : "AM";

    const formattedDateTime = `${day}/${month}/${year} `;

    setCurrentDateTime(formattedDateTime);
  }, []);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("companyName");
      const continentName = await AsyncStorage.getItem("continent");
      const regionName = await AsyncStorage.getItem("region");
      const provinceName = await AsyncStorage.getItem("province");
      const admins = await AsyncStorage.getItem("admin");

      if (value !== null) {
        setBearer(value);
        // setAuthenticated(true);
      }
      if (value1 !== null) {
        setCompany(value1);
      }

      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (admins !== null) {
        setUserType(admins);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const { tableData } = location.state || {};

  const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return v >= 11 && v <= 13 ? "th" : suffixes[v % 10] || "th";
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  };

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      const grouped = tableData.reduce((acc, item) => {
        const categoryID = item.category_id;
        if (!acc[categoryID]) {
          acc[categoryID] = [];
        }
        acc[categoryID].push(item);
        return acc;
      }, {});
      setGroupedData(Object.entries(grouped));
    }
  }, [tableData]);

  function formattedDates(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  return (
    <div className={classes.reportBody}>
      {/* <div style={{ marginTop: '1rem' }} /> */}

      <div className={classes.a42}>
        <div className={classes.header}>
          <p
            style={{
              width: "100%",
              fontSize: 20,
              fontWeight: 700,
              color: "#000",
            }}
          >
            THE REDEEMED CHRISTIAN CHURCH OF GOD
          </p>
          {/* <p style={{ width: '100%' }}>{company}</p> */}
          {/* <h4>Account Activity Period, 01 - 01 (September - September) 2017/2018</h4> */}
          <h4>FIXED ASSET REPORT AS AT {currentDateTime}</h4>
        </div>
        <table className={classes.table2}>
          <thead>
            <tr>
              <th>SN</th>
              <th>Identification Number</th>
              <th>Asset Name</th>
              <th>Category</th>
              <th>Quantity</th>
              <th style={{textAlign:'right'}}>Amount Purchased</th>
            </tr>
          </thead>

         <tbody style={{ whiteSpace: "nowrap" }}>
          {data?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td style={{textAlign:'center'}}>
                {item.identification_number}
              </td>
              <td style={{textAlign:'left'}}>{item.description}</td>
              <td
                style={{ whiteSpace: "wrap" }}
              >
                {
                  item.asset_category
                    ?.description
                }
              </td>
              <td style={{textAlign:'center'}}>{item.quantity}</td>
              <td>{item.amount_purchased}</td>
            </tr>
          ))}
        </tbody>
        </table>
      </div>
    </div>
  );
}
