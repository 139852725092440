import React, { useEffect, useState } from "react";
import classes from './NewLogin2.module.css'
import backgroundImg from '../../assets/promix/LogIn.png'
import Form from 'react-bootstrap/Form';
import { Link, useNavigate, useLocation, json } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import crossedEyeIcon from '../../assets/promix/eye-slash.png';
import { BASE_URL } from "../../Pages/api/api";
import Swal from "sweetalert2";

function ResetPassword() {
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    
    const [token, setToken] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokens = searchParams.get('token');
        setToken(tokens)
        console.log('Here is your token:', tokens); 
        
        // if (tokens) {
        //     handleForgotPassword(token);
        // }
    }, [location.search]);

    const handleLogin = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${BASE_URL}/reset-password`,
                {
                //    password: oldPassword,
                   password: newPassword,
                   password_confirmation: confirmPassword,
                   token: token
                }
            );
                navigate('/');

        } catch (error) {
            let errorMessage = error.response?.data?.message || 'An error occurred';
            if (error.message === 'Network Error') {
                errorMessage = 'Connection error. Please check your internet connection.';
            }
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: JSON.stringify(error.response.data.message),
              });
        } 
        finally {
            setLoading(false);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isButtonDisabled) {
            handleLogin();
        }
    };

    const isButtonDisabled = confirmPassword !== newPassword;

    return (
<div>
    <>
        <div className={classes.background}>
            
            <ToastContainer />
            <div className={classes.mainContainerx}>
                <div className={classes.thecntnt}>
                    <div className={classes.head}>
                        <p className={classes.SignUptxt}>Reset your Password</p>
                        <p className={classes.SignUptxtsub}>The new password must be at least 8 characters.</p>
                    </div>
                    <Form>
                        {/* <Form.Group className={classes.formGroup}>
                            <Form.Label  >Email Address</Form.Label>
                            <Form.Control onChange={(e) => setEmail(e.target.value)} type='text'  placeholder="Enter your email address" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff" }} />
                        </Form.Group> */}
                        {/* <Form.Group className={classes.formGroup}>
                            <Form.Label  >Current Password</Form.Label>
                            <Form.Control onChange={(e) => setOldPassword(e.target.value)} type={showPassword ? 'text' : 'password'} placeholder="Enter your current password" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff", }}  />
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                            </button>
                        </Form.Group> */}
                        <Form.Group className={classes.formGroup}>
                            <Form.Label  >New Password</Form.Label>
                            <Form.Control onChange={(e) => setNewPassword(e.target.value)} type={showPassword1 ? 'text' : 'password'} placeholder="Enter your new password" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff", }}  />
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility1}
                            >
                                {showPassword1 ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                            </button>
                        </Form.Group>
                        <Form.Group className={classes.formGroup}>
                            <Form.Label  >Confirm your Password</Form.Label>
                            <Form.Control onChange={(e) => setConfirmPassword(e.target.value)} type={showPassword2 ? 'text' : 'password'} placeholder="Confirm your new password" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff", }} onKeyPress={handleKeyPress} />
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility2}
                            >
                                {showPassword2 ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                            </button>
                        </Form.Group>
                    </Form>
                    <Button onClick={handleLogin} className={classes.snupbtn} disabled={isButtonDisabled}>
                        {loading ? (
                            <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Processing your request...</span>
                            </>
                        ) : (
                            "Reset Password"
                        )}
                    </Button>
                    {/* <p className={classes.lgin}>Forgot Password? <Link to={'/signup'} style={{ textDecoration: 'none' }}><span>Reset here!</span></Link></p> */}
                </div>
            </div>
        </div>
            <div className={classes.backgroundMobile}>
                
            <ToastContainer />
            <div className={classes.mainContainerMobile}>
                <div className={classes.thecntnt}>
                    <div className={classes.head}>
                        <p className={classes.SignUptxt}>Reset your  Passowrd</p>
                        {/* <p className={classes.SignUptxtsub}>Enter your email to continue</p> */}
                    </div>
                    <Form>
                    {/* <Form.Group className={classes.formGroup}>
                            <Form.Label  >Current Password</Form.Label>
                            <Form.Control onChange={(e) => setOldPassword(e.target.value)} type={showPassword ? 'text' : 'password'} placeholder="Enter your current password" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff", }}  />
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                            </button>
                        </Form.Group> */}
                        <Form.Group className={classes.formGroup}>
                            <Form.Label  >New Password</Form.Label>
                            <Form.Control onChange={(e) => setNewPassword(e.target.value)} type={showPassword1 ? 'text' : 'password'} placeholder="Enter your new password" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff", }}  />
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility1}
                            >
                                {showPassword1 ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                            </button>
                        </Form.Group>
                        <Form.Group className={classes.formGroup}>
                            <Form.Label  >Confirm your Password</Form.Label>
                            <Form.Control onChange={(e) => setConfirmPassword(e.target.value)} type={showPassword2 ? 'text' : 'password'} placeholder="Confirm your new password" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff", }} onKeyPress={handleKeyPress} />
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility2}
                            >
                                {showPassword2 ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                            </button>
                        </Form.Group>
                        {/* <Form.Group className={classes.formGroup}>
                            <Form.Label  >Password</Form.Label>
                            <Form.Control onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} placeholder="Enter your password address" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff", }} onKeyPress={handleKeyPress} />
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                            </button>
                        </Form.Group> */}
                    </Form>
                    <Button onClick={handleLogin} className={classes.snupbtn} disabled={isButtonDisabled}>
                        {loading ? (
                            <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Processing your request...</span>
                            </>
                        ) : (
                            "Reset Password"
                        )}
                    </Button>
                    {/* <p className={classes.lgin}>Forgot Password? <Link to={'/signup'} style={{ textDecoration: 'none' }}><span>Reset here!</span></Link></p> */}
                   
                </div>
            </div>
            </div>
            </>
            </div>
    );

}
export default ResetPassword;