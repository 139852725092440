import React, { useState, useEffect } from 'react';
import classes from './ManageRole.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form, Col, Row } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { MdOutlineRestorePage } from "react-icons/md"
import MobileMenu from '../../Component/Mobile/MobileNav';



export default function CashMovement() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [selectedDate12, setSelectedDate12] = useState('');
  const [user, setUser] = useState('');
  const [cashID, setCashID] = useState('');
   const [load, setLoad] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptions1, setSelectedOptions1] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);
  const [totalAmountDebit, setTotalAmountDebit] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const [formData, setFormData] = useState([{ sn: 1, accountFrom: '',  accountTo: '', amount: '', description: '', }]);
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [foundCashArrays, setFoundCashArrays] = useState([]);
  const [tableDatas, setTableDatas] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [foundCashUUID, setFoundCashUUID] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [selectedRole1, setSelectedRole1] = useState("");
  const [phone, setPhone] = useState("");
  const [journalls, setJournalls] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [glname, setGlname] = useState("");
  const [balance, setBalance] = useState("");
  const [selectedDirection, setSelectedDirection] = useState("");
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedSubCategory1, setSelectedSubCategory1] = useState('');
  const [glname1, setGlname1] = useState("");
  const [balance1, setBalance1] = useState("");
  const [selectedDirection1, setSelectedDirection1] = useState("");
  const [selectedDate1, setSelectedDate1] = useState('');
  const [selectedChart, setSelectedChart] = useState('')
  const [tableData2, setTableData2] = useState([]);
  const [glcode, setGlcode] = useState("");
   const [minDate, setMinDate] = useState("");
    const [maxDate, setMaxDate] = useState("");
  const [userType, setUserType] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [openNav,setOpenNav] = useState(false);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');
      const current = await AsyncStorage.getItem('currentMonth');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }

      if (current !== null) {
        console.log(current);
        const date = new Date(current);
      
        setCurrentMonth(date);
      
        const startOfDateRange = new Date(1900, 0, 1);
     
        const endOfDateRange = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      
        // Format date function as 'YYYY-MM-DD'
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); 
          const day = String(date.getDate()).padStart(2, '0');     
          return `${year}-${month}-${day}`;
        };
      
        setMinDate(formatDate(startOfDateRange));
        setMaxDate(formatDate(endOfDateRange));
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const calculateTotalAmount = () => {
    const total = formData.reduce((acc, item) => {
      return acc + parseFloat(item.amount || 0);
    }, 0);
  
    const formattedTotal = total.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  
    setTotalAmountDebit(formattedTotal);
  };

   useEffect(() => {
      calculateTotalAmount();
      // calculateTotalAmountCredit();
    }, [formData]);

    const handleAccountChange = (selectedOption, rowIndex) => {
      setSelectedOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        updatedOptions[rowIndex] = selectedOption;
        return updatedOptions;
      });
  
      const selectedChart = tableDatas.find((chart) => chart.id === selectedOption.value);
  
      if (selectedChart) {
        const updatedFormData = [...formData];
        updatedFormData[rowIndex].accountFrom = selectedChart.id;
        setFormData(updatedFormData);
      }
    };
    const handleAccountChange1 = (selectedOption, rowIndex) => {
      setSelectedOptions1((prevOptions) => {
        const updatedOptions = [...prevOptions];
        updatedOptions[rowIndex] = selectedOption;
        return updatedOptions;
      });
  
      const selectedChart1 = tableDatas.find((chart) => chart.id === selectedOption.value);
  
      if (selectedChart1) {
        const updatedFormData = [...formData];
        updatedFormData[rowIndex].accountTo = selectedChart1.id;
        setFormData(updatedFormData);
      }
    };
  

  
    const deleteRow = (index) => {
      const updatedData = formData.filter((_, i) => i !== index);
      setFormData(updatedData);
    };
  
    const handleFormChange = (index, field, value) => {
      let formattedValue = value;
  
      // Format the amount field
      if (field === 'amount' && value !== '') {
        formattedValue = (Number(value.replace(/\D/g, '')) || '').toLocaleString();
      }
  
      const updatedFormData = [...formData];
      updatedFormData[index][field] = formattedValue;
      setFormData(updatedFormData);
    };

    const addRow = () => {
      const newRow = {
        sn: formData.length + 1,
        accountTo: '',
        accountFrom: '',
        amount: '',
        description: '',
       
      };
  
      setFormData([...formData, newRow]);
    };

    const fetchCharts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/account`, { headers });
        const results = response.data?.data;
  
        const options = results.map((chart) => ({
          label: `${chart.gl_code} - ${chart.gl_name}`,
          value: chart.id,
        }));
        
  
        setTableDatas(results);
        setSelectOptions(options);
      } catch (error) {
        if (error.response && error.response.status === 401) {
  
          navigate('/login');
        } else {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setSelectOptions([]);
        }
      } finally {
        setIsLoading(false);
      }
    };
  
  
    useEffect(() => {
      if (bearer) {
        fetchCharts();
  
      }
    }, [bearer]);

    const createJournal = async () => {
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to process this transaction.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, process it!',
        cancelButtonText: 'No, cancel',
      });
  
      if (!confirmed.isConfirmed) {
        return;
      }
      
      setLoad(true);
  
      try {
        const accountFrom = formData.map((row) => row.accountFrom).filter((name) => name !== undefined);
        const accountTo = formData.map((row) => row.accountTo).filter((name) => name !== undefined);
        const descriptions = formData.map((row) => row.description).filter((name) => name !== undefined);
        const amounts = formData.map((row) => row.amount).filter((name) => name !== undefined);
        // const debitAmount = totalAmountDebit.replace(/,/g, '').replace(/\.00$/, '');
  
  
  
        // console.log(accountNames, creditAmount, debitAmount, descriptions, amounts, directions, "information")
        const response = await axios.post(`${BASE_URL}/cash_movement/create`,
          {
           transaction_date: selectedDate,
           from: accountFrom,
           to: accountTo,
           description: descriptions,
           amount: amounts
          },
          { headers }
        );
        // console.log(accountNames, creditAmount, debitAmount, descriptions, amounts, directions, "AFTER")
        // console.log(response.data.message, "heeee");
  
        Swal.fire({
          icon: 'success',
          title: 'Successful',
          text: response.data.message,
        }).then(() => {
          window.location.reload(); 
        });
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
          
            console.log(errorMessage);
          }
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        //   console.log(error);
      } finally {
        setLoad(false);
      }
    };

    const createJournals = async () => {
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to reverse this transaction.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reverse it!',
        cancelButtonText: 'No, cancel',
      });
  
      if (!confirmed.isConfirmed) {
        return;
      }
      
      setLoad(true);
  
      try {
        const response = await axios.post(`${BASE_URL}/cash_movement/reversal`,
          {
           uuid: foundCashUUID
          },
          { headers }
        );
       
  
        Swal.fire({
          icon: 'success',
          title: 'Successful',
          text: response.data.message,
        });
        navigate(-1);
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
          
            console.log(errorMessage);
          }
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        //   console.log(error);
      } finally {
        setLoad(false);
      }
    };

    const handleValueChange1 = (value, index) => {
      const updatedFormData = [...formData];
      updatedFormData[index] = {
        ...updatedFormData[index],
        amount: value,
      };
      setFormData(updatedFormData);
    };
  

  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/cash_movement`, { headers });
      const results = response.data?.data;
      console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-sub-categories`, { headers });
      const subCat = response.data?.data;
      setTableData2(subCat);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchData();
      fetchData1();
    }
  }, [bearer]);

  const handleSubCatChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };
  const handleSubCatChange1 = (event) => {
    setSelectedSubCategory1(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleDateChange1 = (event) => {
    setSelectedDate1(event.target.value);
  };

  const createAccount = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/add`,
        {
          sub_category_id: selectedSubCategory,
          gl_name: glname,
          opening_balance: balance,
          direction: selectedDirection,
          transaction_date: selectedDate

        },
        { headers }
      );
      console.log(response)
      fetchData();
      handleClose();
      setGlcode('');
      setGlname('');
      setBalance('');
      setSelectedSubCategory('');
      setSelectedDate('');
      // return
      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const handleEyeClick = (id) => {

    const foundCharts = tableData.find(item => item.id === id);
    const chartId = foundCharts.id;
    setSelectedChart(chartId)

    const { gl_code, gl_name, balance, direction, transaction_date } = foundCharts;
    setSelectedSubCategory1(gl_code || '');
    setGlname1(gl_name || '');
    setBalance1(balance || '');
    setSelectedDirection1(direction || '');
    setSelectedDate1(transaction_date || '');
    // const selectedRole = roles.length > 0 ? roles[0].id : '';
    // setSelectedRole(selectedRole);

    setShow1(true);
    setEyeClicked(true);
  };
  useEffect(() => {

  }, { selectedChart }

  )



  const editUser = async (id) => {
    setUpdateLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/users/update-user`,
        {
          name: fullName1,
          id: selectedId,
          email: email1,
          phone_no: phone1,
          role: selectedRole1,
          user_id: selectedUser
        },
        { headers }
      );

      fetchData();
      handleClose1();
      toast.success(response.data.message);
      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setUpdateLoading(false);
    }
  };


  const handleTrashClick = async (uuid) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to reverse this operations.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/delete-journal-entries?uuid=${uuid}`, { headers });
      fetchData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };


  const handleTrashClick12 = async (uuid) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this operations.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/delete-entries?uuid=${uuid}`, { headers });
      fetchData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  const handleView = async (uuid) => {
    try {
      const response = await axios.get(`${BASE_URL}/journal-entries-details?uuid=${uuid}`, { headers });
      const result = response.data.data;
      handleShow();
      setJournalls(result);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(errorMessage);
      }
    }
  };
  


  const AddJournal = () => {
    navigate('/accounting/create_cash_movement');
  }

  const handleDirectionChange = (e) => {
    setSelectedDirection(e.target.value);
  };
  const handleDirectionChange1 = (e) => {
    setSelectedDirection1(e.target.value);
  };

  const handleValueChange = (value, name, values) => {
    setBalance(value); // Update the balance state
    console.log(value, name, values);
  };
  

  const handleViews = (id) => {

    const foundCash = tableData.find(item => item.id === id);
    const cashId = foundCash.id;
    const cashUUID = foundCash.uuid;
    setCashID(cashId);
    setFoundCashUUID(cashUUID);
    const foundCashArray = foundCash ? [foundCash] : [];
    setFoundCashArrays(foundCashArray);

    const {transaction_date  } = foundCash;
    setSelectedDate12(transaction_date || '');
  

    setShow1(true);
    setEyeClicked(true);
  };

  const handleExcelExport = async () => {
    // Create a workbook and a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data Sheet');

    // Define columns (headers)
    worksheet.columns = [
      { header: 'S/N', key: 'index', width: 10 },
      { header: 'Account Code', key: 'gl_code', width: 20 },
      { header: 'Account Name', key: 'gl_name', width: 30 },
      { header: 'Account Type', key: 'class_description', width: 20 },
      { header: 'Category', key: 'category_description', width: 20 },
      { header: 'Sub Category', key: 'subcategory_description', width: 20 }
    ];

    // Add data to worksheet
    tableData.forEach((item, index) => {
      worksheet.addRow({
        index: index + 1,
        gl_code: item.gl_code,
        gl_name: item.gl_name,
        class_description: item.class?.description,
        category_description: item.category?.description,
        subcategory_description: item.subcategory?.description
      });
    });

    // Set up file saving
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(blob, 'Charts of Account.xlsx');
  };


  return (
    <div>
        {
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    
      <ToastContainer />
      {/* <MainDashoard /> */}

      <div className={classes.finishedbodyCont}>
        <div >
          <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>
          {/* <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3>Manage Charts of Account</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <h3 style={{ color: '#2D995F' }}>{user.toLocaleUpperCase()}</h3>
              <p style={{marginTop: -10}}>{admin}</p>
                            <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {`(${admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''})`}
</p>

            </div>
          </div> */}
        </div>

        <div className={classes.topSpace}>
          <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  15px" }}>Cash Movement</h3>
          <p style={{ margin: " 0 10px 20px 15px" }}>View cash movement</p>
          <div className={classes.mainform}>

            <div className={classes.loandgrantcards}>

              <div className={classes.loandethead}>
                <div className={classes.formLabel}>
                </div>
                <div className={classes.formIntBtn}>
                  {/* <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to upload new journal entry</Tooltip>}
                  >
                    <Button variant="success"  >  Upload</Button>
                  </OverlayTrigger> */}
                  <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add new cash movement</Tooltip>}
                  >
                    <Button variant="success" onClick={handleShow} className={classes.btn2}>  Add New</Button>
                  </OverlayTrigger>
                </div>
              </div>
              <Modal size='xl' show={show} onHide={handleClose} animation={false} >
                <Modal.Header closeButton>
                  <Modal.Title>Cash Movement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form >
                  <div  style={{marginTop: 10}}>
                  <Row className="justify-content-left">
        <Col md={3}>
          <Form>
            <Form.Group controlId="transactionDate">
              <Form.Label className="mb-2">Transaction Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter transaction date"
                className="mb-3"
                onChange={handleDateChange}
                                     value={selectedDate}
                                     min={minDate}
                                     max={maxDate} 
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
                
</div>
<div style={{ marginTop: 20 }} />
<div className="table-responsive">
                              <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                  <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                  <tr>
                                    {/* <th>#</th> */}
                                    {/* <th style={{ width: '15%', }}>Transaction Date</th> */}
                                    <th style={{ width: '28%', }}>From Account</th>
                                    <th style={{ width: '28%', }}>To Account</th>
                                    <th style={{ width: '25%', }}>Description</th>
                                    {/* <th style={{ width: '15%', }}>DR/CR</th> */}
                                    <th style={{ width: '19%', }}>Amount</th>
                                    {/* <th style={{ width: '15%', }}>Cheque/Teller Number</th> */}

                                    <th >
                                    <OverlayTrigger
                                      placement="top"
                                      className={classes.btndf1}
                                      overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to add new row</Tooltip>}
                                    >
                                      <Button variant="primary" onClick={() => addRow()} style={{ borderRadius: "50px" }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    </OverlayTrigger>
                                    </th>

                                  </tr>
                                </thead>
                                <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                  {formData.map((row, index) => (
                                    <tr key={index}>
                                      {/* <td>{row.sn}</td> */}
                                      {/* <td>
                                        <input
                                          value={row.date}
                                          onChange={(e) => handleFormChange(index, 'date', e.target.value)}
                                          className="form-control"
                                          type="date"
                                          min={minDate}
                                          max={maxDate}                        
                                        />
                                      </td> */}
                                      <td >
                                        <Select
                                          value={selectedOptions[index]}
                                          onChange={(selectedOption) => handleAccountChange(selectedOption, index)}
                                          options={selectOptions}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (provided) => ({
                                              ...provided,
                                              // maxHeight: '200px', 
                                              // overflowY: 'auto',
                                            }),
                                          }}
                                        />

                                      </td>
                                      <td >
                                        <Select
                                          value={selectedOptions1[index]}
                                          onChange={(selectedOption) => handleAccountChange1(selectedOption, index)}
                                          options={selectOptions}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (provided) => ({
                                              ...provided,
                                              // maxHeight: '200px', 
                                              // overflowY: 'auto',
                                            }),
                                          }}
                                        />

                                      </td>
                                      <td>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          rows={1}
                                          value={row.description}
                                          onChange={(e) => handleFormChange(index, 'description', e.target.value)}
                                        />
                                      </td>
                                      {/* <td>
                                        <Form.Select
                                          value={row.DR_CR}
                                          onChange={(e) => handleFormChange(index, 'DR_CR', e.target.value)}
                                          className="form-control"
                                        >
                                          <option value="">Select</option>
                                          <option value="1">DR</option>
                                          <option value="2">CR</option>
                                        </Form.Select>
                                      </td> */}
                                      <td>
                                        <CurrencyInput
                                          name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                                          decimalsLimit={2}
                                          value={row.amount}
                                          className="form-control"
                                          onValueChange={(value) => handleValueChange1(value, index)}
                                          style={{ textAlign: "right", border: "none" }}
                                        />
                                        {/* <input
                                                type="text"
                                                className="form-control"
                                                value={row.amount}
                                                onChange={(e) => handleFormChange(index, 'amount', e.target.value)}
                                                style={{ textAlign: "right" }}
                                              /> */}
                                      </td>
                                      {/* <td>
                                        <input
                                          value={row.cheque}
                                          onChange={(e) => handleFormChange(index, 'cheque', e.target.value)}
                                          className="form-control"
                                          type="text"
                                        />
                                      </td> */}


                                      <td style={{ textAlign: "left" }}>
                                      <OverlayTrigger
                                        placement="top"
                                        className={classes.btndf1}
                                        overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to delete row</Tooltip>}
                                      >
                                        <Button variant="danger" onClick={() => deleteRow(index)} style={{ borderRadius: "50px" }}>
                                          <i className="far fa-trash-alt"></i>
                                        </Button>
                                      </OverlayTrigger>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                            </div>
                  </Form>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="danger" onClick={handleClose}>
                    Go back
                  </Button>
                  <Button
                          className={classes.longbtn}
                            variant='success'
                            onClick={createJournal}
                            // disabled={!isEqual}
                            style={{ padding: '5px 30px',  }}>
                            {load ? (
                              <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                              </>
                            ) : (
                              "Save"
                            )}
                          </Button>
                </Modal.Footer>
              </Modal>

              <Modal size='xl' show={show1} onHide={handleClose1} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Cash Movement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form >
                  <div  style={{marginTop: 10}}>
                  <Row className="justify-content-left">
        <Col md={6}>
          <Form>
            <Form.Group controlId="transactionDate">
              <Form.Label className="mb-2">Transaction Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter transaction date"
                className="mb-3"
               
                                     value={selectedDate12}
                                     min={minDate}
                                     max={maxDate} 
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
                
</div>
<div style={{ marginTop: 20 }} />
<div className="table-responsive">
                              <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                  <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                  <tr>
                                    <th>#</th>
                                    {/* <th style={{ width: '15%', }}>Transaction Date</th> */}
                                    <th style={{ width: '26%', }}>From Account</th>
                                    <th style={{ width: '26%', }}>To Account</th>
                                    <th style={{ width: '30%', }}>Description</th>
                                    {/* <th style={{ width: '15%', }}>DR/CR</th> */}
                                    <th style={{ width: '18%', }}>Amount</th>
                                    {/* <th style={{ width: '15%', }}>Cheque/Teller Number</th> */}

                                    {/* <th >
                                    <OverlayTrigger
                                      placement="top"
                                      className={classes.btndf1}
                                      overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to add new row</Tooltip>}
                                    >
                                      <Button variant="primary" onClick={() => addRow()} style={{ borderRadius: "50px" }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    </OverlayTrigger>
                                    </th> */}

                                  </tr>
                                </thead>
                                <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                  {foundCashArrays.map((row, index) => (
                                    <tr key={index}>
                                      <td>{row.sn}</td>
                                      {/* <td>
                                        <input
                                          value={row.date}
                                          onChange={(e) => handleFormChange(index, 'date', e.target.value)}
                                          className="form-control"
                                          type="date"
                                          min={minDate}
                                          max={maxDate}                        
                                        />
                                      </td> */}
                                      <td >
                                      {row?.from_account?.gl_name}
                                        {/* <Select
                                          value={selectedOptions[index]}
                                          onChange={(selectedOption) => handleAccountChange(selectedOption, index)}
                                          options={selectOptions}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (provided) => ({
                                              ...provided,
                                              // maxHeight: '200px', 
                                              // overflowY: 'auto',
                                            }),
                                          }}
                                        /> */}

                                      </td>
                                      <td >
                                      {row?.to_account?.gl_name}
                                        {/* <Select
                                          value={selectedOptions1[index]}
                                          onChange={(selectedOption) => handleAccountChange1(selectedOption, index)}
                                          options={selectOptions}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (provided) => ({
                                              ...provided,
                                              // maxHeight: '200px', 
                                              // overflowY: 'auto',
                                            }),
                                          }}
                                        /> */}

                                      </td>
                                      <td>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          rows={1}
                                          value={row.description}
                                          disabled
                                          onChange={(e) => handleFormChange(index, 'description', e.target.value)}
                                        />
                                      </td>
                                      {/* <td>
                                        <Form.Select
                                          value={row.DR_CR}
                                          onChange={(e) => handleFormChange(index, 'DR_CR', e.target.value)}
                                          className="form-control"
                                        >
                                          <option value="">Select</option>
                                          <option value="1">DR</option>
                                          <option value="2">CR</option>
                                        </Form.Select>
                                      </td> */}
                                      <td>
                                        <CurrencyInput
                                          name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                                          decimalsLimit={2}
                                          value={row.amount}
                                          disabled
                                          className="form-control"
                                          onValueChange={(value) => handleValueChange1(value, index)}
                                          style={{ textAlign: "right", border: "none" }}
                                        />
                                        {/* <input
                                                type="text"
                                                className="form-control"
                                                value={row.amount}
                                                onChange={(e) => handleFormChange(index, 'amount', e.target.value)}
                                                style={{ textAlign: "right" }}
                                              /> */}
                                      </td>
                                      {/* <td>
                                        <input
                                          value={row.cheque}
                                          onChange={(e) => handleFormChange(index, 'cheque', e.target.value)}
                                          className="form-control"
                                          type="text"
                                        />
                                      </td> */}


                                      {/* <td style={{ textAlign: "left" }}>
                                      <OverlayTrigger
                                        placement="top"
                                        className={classes.btndf1}
                                        overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to delete row</Tooltip>}
                                      >
                                        <Button variant="danger" onClick={() => deleteRow(index)} style={{ borderRadius: "50px" }}>
                                          <i className="far fa-trash-alt"></i>
                                        </Button>
                                      </OverlayTrigger>
                                      </td> */}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                            </div>
                  </Form>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="danger" onClick={handleClose}>
                    Go back
                  </Button>
                  <Button
                          className={classes.longbtn}
                            variant='success'
                            onClick={createJournals}
                            // disabled={!isEqual}
                            style={{ padding: '5px 30px',  }}>
                            {load ? (
                              <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Reversing, Please wait...</span>
                              </>
                            ) : (
                              "Reverse"
                            )}
                          </Button>
                </Modal.Footer>
              </Modal>


              <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                  <div className={classes.actionsBtns}>
                    <Button variant='success'>Copy</Button>
                    <Button variant='success' >Excel</Button>
                    <Button variant='success'>PDF</Button>
                    <Button variant='success' className={classes.diffbtn}>Column visibility</Button>
                  </div>
                  {/* <div className={classes.show}>
                    <label className="d-flex justify-content-start align-items-center">
                      Show
                      <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                      //  value={entriesPerPage}
                      //     onChange={(e) => {
                      //     setEntriesPerPage(parseInt(e.target.value));
                      //     setCurrentPage(1);
                      //     }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      entries
                    </label>
                  </div> */}
                </div>
                <div className="text-right modal-effect ">
                  <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="mr-2">Search:</div>
                      <input
                        type="search"
                        value={searchTerm}
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-controls="DataTables_Table_0"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          // setCurrentPage(1);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{marginTop: 10}}/>
              <div className={classes.mainTable}>
                {roleLoading ? (
                  <p>Fetching data...</p>
                ) : (
                  <div className="table-responsive">
                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                      <thead style={{ whiteSpace: 'nowrap' }}>
                        <tr>
                          <th>S/N</th>
                          <th>UUID</th>
                          <th>Transaction Date</th>
                          {/* <th>JV Number</th> */}
                          <th>Description</th>
                          <th>From Account</th>
                          <th>To Account</th>
                          {/* <th style={{textAlign: "right"}}>Total Debit</th> */}
                          <th style={{textAlign: "right"}}>Total Amount</th>
                          {/* <th>Created At</th> */}
                          {/* <th>Opening Balance</th> */}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: 'nowrap',  }}>
                        {displayedData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td style={{ whiteSpace: 'wrap',  }}>{item.uuid}</td>
                            <td style={{ textAlign: "left" }}>{item.transaction_date}</td>
                            <td >{item.description}</td>
                            <td style={{ whiteSpace: 'wrap',  }}>{item.from_account?.gl_name}</td>
                            <td style={{ whiteSpace: 'wrap',  }}>{item.to_account?.gl_name}</td>
                           
                            {/* <td style={{textAlign: "right"}}>{parseFloat(item?.total_debit).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}</td> */}
                            <td style={{textAlign: "right"}}>{parseFloat(item?.amount).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}</td>
                                         {/* <td>{formatDate(item.created_at)}</td> */}
                            <td >
                              <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                                  {/* <div
                                    onClick={() => handleTrashClick(item.uuid)}
                                    
                                    style={{ padding: '0 10px', cursor: "pointer", }}
                                  >
                                    <MdOutlineRestorePage fontSize={20} style={{color: "#2D995F", }} />
                                  </div> */}
                                  <div   onClick={() => handleViews(item.id)}  className="mr-1 btn btn-success-soft btn-sm">
                                                                                <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                                                            </div>
                                  {/* <div   onClick={() => handleTrashClick12(item.uuid)}  className="mr-1 btn btn-danger-soft btn-sm">
                                                                                <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                                                            </div> */}
                                  </div> 

                            
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              <div className={classes.endded}>
                <p>
                  Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                </p>
                <div style={{ display: 'flex' }}>
                  <button
                    style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, page) => {
                    // Show only 5 pages or less if available
                    if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                      return (
                        <button
                          key={page + 1}
                          style={{
                            marginLeft: '0.4rem',
                            marginRight: '0.4rem',
                            fontSize: '14px',
                            fontFamily: 'nunito',
                            fontWeight: 400,
                            color: page + 1 === currentPage ? '#ffffff' : '#000',
                            backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                            height: '2.5rem',
                            borderRadius: '89px',
                            padding: '0.5rem',
                            border: 'none',
                            width: '40px',
                            cursor: "pointer"
                          }}
                          onClick={() => setCurrentPage(page + 1)}
                        >
                          {page + 1}
                        </button>
                      );
                    }
                    return null;
                  })}
                  <button
                    style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>






    </div >
  )
}