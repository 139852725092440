import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import classes from './CreateSales.module.css';
import classes from '../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../Main Dashboard/MainDashoard';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import JournalDash from '../Journal Dashboard/JournalDash';
import LatestDash from '../LatestDashboard/LatestDash';
// import { BASE_URL } from '../../api/api';
// import classes from './LoanRepayment.module.css'
// import favicon from '../../Images/faviconn.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function UpdateCashMovement() {
  const navigate = useNavigate();
  const location = useLocation();
    const { foundCash } = location.state || {};
    const foundCashArray = foundCash ? [foundCash] : [];
  const [bearer, setBearer] = useState('');
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState([{ sn: 1, accountFrom: '',  accountTo: '', amount: '', description: '', }]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [totalAmountCredit, setTotalAmountCredit] = useState('');
  const [totalAmountDebit, setTotalAmountDebit] = useState(foundCash?.amount || '');
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState(false);
  const [journalCreated, setJournalCreated] = useState(false);
  const [isEqual, setIsEqual] = useState(false);
  const [userType, setUserType] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [jvNumber, setJvNumber] = useState('');
  const [province, setProvince] = useState('');
  const [sICode, setSICode] = useState('');
  const [date, setSelectedDate] = useState(foundCash?.transaction_date || '');
  const [loading, setLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState('');
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');
      const current = await AsyncStorage.getItem('currentMonth');


      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);

      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (current !== null) {
        console.log(current);
        const date = new Date(current);
      
        setCurrentMonth(date);
      
        const startOfDateRange = new Date(1900, 0, 1);
     
        const endOfDateRange = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      
        // Format date function as 'YYYY-MM-DD'
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); 
          const day = String(date.getDate()).padStart(2, '0');     
          return `${year}-${month}-${day}`;
        };
      
        setMinDate(formatDate(startOfDateRange));
        setMaxDate(formatDate(endOfDateRange));
      
        console.log("Date Range:", formatDate(startOfDateRange), "to", formatDate(endOfDateRange));
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };
  //   console.log("user:", user)

  useEffect(() => {
    readData();
  }, []);

  // const UserGreeting = ({ user }) => {
  // Ensure `user` is a string
  const userString = typeof user === 'string' ? user : '';


  // const calculateTotalAmount = () => {
  //   const total = foundCash.reduce((acc, item) => {
  //     return acc + parseFloat(item.amount || 0);
  //   }, 0);
  
  //   const formattedTotal = total.toLocaleString('en-US', {
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   });
  
  //   setTotalAmountDebit(formattedTotal);
  // };
  

  // const calculateTotalAmountCredit = () => {
  //   const total = formData.reduce((acc, item) => {
  //     return item.DR_CR === '2' ? acc + parseFloat(item.amount || 0) : acc;
  //   }, 0);

  //   const formattedTotal = total.toLocaleString('en-US', {
  //     minimumIntegerDigits: 1,
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   });

  //   setTotalAmountCredit(formattedTotal);
  // };



  // useEffect(() => {
  //   calculateTotalAmount();
  //   // calculateTotalAmountCredit();
  // }, [foundCash]);

  const handleAccountChange = (selectedOption, rowIndex) => {
    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[rowIndex] = selectedOption;
      return updatedOptions;
    });

    const selectedChart = tableData.find((chart) => chart.id === selectedOption.value);

    if (selectedChart) {
      const updatedFormData = [...formData];
      updatedFormData[rowIndex].accountFrom = selectedChart.id;
      setFormData(updatedFormData);
    }
  };
  const handleAccountChange1 = (selectedOption, rowIndex) => {
    setSelectedOptions1((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[rowIndex] = selectedOption;
      return updatedOptions;
    });

    const selectedChart1 = tableData.find((chart) => chart.id === selectedOption.value);

    if (selectedChart1) {
      const updatedFormData = [...formData];
      updatedFormData[rowIndex].accountTo = selectedChart1.id;
      setFormData(updatedFormData);
    }
  };



  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };

  const handleFormChange = (index, field, value) => {
    let formattedValue = value;

    // Format the amount field
    if (field === 'amount' && value !== '') {
      formattedValue = (Number(value.replace(/\D/g, '')) || '').toLocaleString();
    }

    const updatedFormData = [...formData];
    updatedFormData[index][field] = formattedValue;
    setFormData(updatedFormData);
  };



  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const options = results.map((chart) => ({
        label: `${chart.gl_code} - ${chart.gl_name}`,
        value: chart.id,
      }));
      

      setTableData(results);
      setSelectOptions(options);
    } catch (error) {
      if (error.response && error.response.status === 401) {

        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);




  const addRow = () => {
    const newRow = {
      sn: formData.length + 1,
      accountTo: '',
      accountFrom: '',
      amount: '',
      description: '',
     
    };

    setFormData([...formData, newRow]);
  };


  const fetchInvoiceCode = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      //   console.log(resultss);
      setJvNumber(resultss);
      // console.log(invoiceData)
      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchInvoiceCode();
    }
  }, [bearer]);


  const createJournal = async () => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to reverse this transaction.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, reverse it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return;
    }
    
    setLoad(true);

    try {
      const response = await axios.post(`${BASE_URL}/cash_movement/reversal`,
        {
         uuid: foundCash?.uuid
        },
        { headers }
      );
     

      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      navigate(-1);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
        
          console.log(errorMessage);
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: JSON.stringify(error.response.data.message),
      });
      //   console.log(error);
    } finally {
      setLoad(false);
    }
  };

  const goBack = () => {
    navigate(-1)
  }

  const handleValueChange1 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      amount: value,
    };
    setFormData(updatedFormData);
  };




  // useEffect(() => {
  //   // Compare the total debit and total credit values
  //   setIsEqual(totalAmountDebit === totalAmountCredit);
  // }, [totalAmountDebit, totalAmountCredit]);


  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <LatestDash />
            <div className={classes.finishedbodyCont}>
            <div className='newBody'>
              <div className={classes.newWidth}>

              <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 10px 10px 0  20px" }}>Cash Movement</h3>
              <p style={{margin:" 0 10px 20px 20px" }}>View cash movement</p>
                
                {/* <h3 style={{ marginTop: '1rem', fontSize: 20, fontWeight: 700 }}>Journal Entries</h3> */}
                {/* <div className="col-md-6" >
                <div className="form-group row" style={{marginRight: 150}}>
                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{textAlign: "right"}}>JV Number</label>
                                <div className="col-sm-6">
                                    <input className="form-control" required="" type="text" value={jvNumber} onChange={(e) => setJvNumber(e.target.value)} name="jvnumber" disabled />
</div>
</div>
</div> */}
                <div className="col-md-6"  style={{marginTop: 10}}>
                <div className="form-group row" style={{marginRight: 150}}>
                                <label for="example-text-input" className="col-sm-4 col-form-label font-weight-400" style={{textAlign: "right"}}>Transaction Date</label>
                                <div className="col-sm-6">
                                    <input className="form-control" required=""
                                     type="date"
                                     onChange={handleDateChange}
                                     value={date}
                                     min={minDate}
                                     max={maxDate}   />
</div>
</div>
</div>

                <div className={classes.topPadding}>
                  <div className={`${classes.formSecCont}`}>
                    <div className={`card-body ${classes.awrow112}`} style={{ border: 'none' }}>
                      <div className={`row ${classes.awrow1} ${classes.mainform}`} >
                        <div className={`row ${classes.awrow1}`}>
                          <div className={classes.maisdsdfnform}>
                          <div style={{ marginTop: 20 }} />
                            <div className="table-responsive">
                              <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                  <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                  <tr>
                                    <th>#</th>
                                    {/* <th style={{ width: '15%', }}>Transaction Date</th> */}
                                    <th style={{ width: '26%', }}>From Account</th>
                                    <th style={{ width: '26%', }}>To Account</th>
                                    <th style={{ width: '30%', }}>Description</th>
                                    {/* <th style={{ width: '15%', }}>DR/CR</th> */}
                                    <th style={{ width: '18%', }}>Amount</th>
                                    {/* <th style={{ width: '15%', }}>Cheque/Teller Number</th> */}

                                    {/* <th >
                                    <OverlayTrigger
                                      placement="top"
                                      className={classes.btndf1}
                                      overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to add new row</Tooltip>}
                                    >
                                      <Button variant="primary" onClick={() => addRow()} style={{ borderRadius: "50px" }}>
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                    </OverlayTrigger>
                                    </th> */}

                                  </tr>
                                </thead>
                                <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                  {foundCashArray.map((row, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      
                                      
                                      <td >
                                      {row?.from_account?.gl_name}
                                        
                                      </td>
                                      <td >
                                      {row?.to_account?.gl_name}
                                       

                                      </td>
                                      <td>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          rows={1}
                                          disabled
                                          value={row.description}
                                          onChange={(e) => handleFormChange(index, 'description', e.target.value)}
                                        />
                                      </td>
                                      
                                      <td>
                                        <CurrencyInput
                                          name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                                          decimalsLimit={2}
                                          value={row.amount}
                                          disabled
                                          className="form-control"
                                          onValueChange={(value) => handleValueChange1(value, index)}
                                          style={{ textAlign: "right", border: "none" }}
                                        />
                                       
                                      </td>
                                     


                                      
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                            </div>
                          </div>

                        </div>

                        <div style={{ marginTop: 20 }} />
                        <div className={classes.form_align}>
                          <Form.Group className="mb-3">
                            <Form.Label>Total Amount:</Form.Label>
                            <CurrencyInput
                                          name='total_amount' // Provide a unique name for each CurrencyInput
                                          decimalsLimit={2}
                                          value={totalAmountDebit}
                                          disabled
                                          className="form-control"
                                         
                                          style={{ textAlign: "right", border: "none" }}
                                        />
                            {/* <Form.Control
                              type="text"
                              value={totalAmountDebit}
                              name="total"
                              readOnly
                              style={{ textAlign: 'right' }}
                            /> */}
                          </Form.Group>
                          {/* <Form.Group className="mb-3">
                            <Form.Label>Total Credit:</Form.Label>
                            <Form.Control
                              type="text"
                              value={totalAmountCredit}
                              name="total"
                              readOnly
                              style={{ textAlign: 'right' }}
                            />
                          </Form.Group> */}
                        </div>


                        <div class={`modal-footer ${classes.overlaybtn100}`} style={{ marginTop: '20px', paddingRight: '10px' }}>
                        <Button
                          className={classes.longbtn}
                            variant='danger'
                            onClick={goBack}
                            style={{ padding: '5px 30px', backgroundColor: '#cecece', color: 'black', borderColor: '#cecece', marginRight: 10 }}> 
                            Back                          
                            </Button>

                          <Button
                          className={classes.longbtn}
                            variant='danger'
                            onClick={createJournal}
                            // disabled={!isEqual}
                            style={{ padding: '5px 30px',  }}>
                            {load ? (
                              <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Reversing, Please wait...</span>
                              </>
                            ) : (
                              "Reverse"
                            )}
                          </Button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            {/* <!--/.main content--> */}
          </div>
        </div>
        {/* <InfoFooter /> */}
        {/* <!--/.footer content--> */}
        <div className="overlay"></div>
      </div>
      {/* <!--/.wrapper--> */}


    </div>
  

  );
}

export default UpdateCashMovement;