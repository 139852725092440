import React, { useState } from "react";
import classes from './NewLogin2.module.css'
import backgroundImg from '../../assets/promix/LogIn.png'
import Form from 'react-bootstrap/Form';
import { Link, useNavigate, useLocation, json } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import crossedEyeIcon from '../../assets/promix/eye-slash.png';
import { BASE_URL } from "../../Pages/api/api";
import Swal from "sweetalert2";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const handleLogin = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${BASE_URL}/forgot-password`,
                {
                    email: email,
                }
            );
            Swal.fire({
                icon: 'success',
                title: 'Successful',
                text: response.data.message,
              });
              navigate('/');
              setEmail('');

        } catch (error) {
            let errorMessage = error.response?.data?.message || 'An error occurred';
            if (error.message === 'Network Error') {
                errorMessage = 'Connection error. Please check your internet connection.';
            }
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: JSON.stringify(error.response.data.message),
              });
        } 
        finally {
            setLoading(false);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isButtonDisabled) {
            handleLogin();
        }
    };

    const isButtonDisabled = !email;


    return (
<div>
    <>
        <div className={classes.background}>
            
            <ToastContainer />
            <div className={classes.mainContainerx}>
                <div className={classes.thecntnt}>
                    <div className={classes.head}>
                        <p className={classes.SignUptxt}>Reset your Password</p>
                        <p className={classes.SignUptxtsub}>Enter your email to continue</p>
                    </div>
                    <Form>
                        <Form.Group className={classes.formGroup}>
                            <Form.Label  >Email Address</Form.Label>
                            <Form.Control onChange={(e) => setEmail(e.target.value)} type='text'  placeholder="Enter your email address" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff" }} />
                        </Form.Group>
                        {/* <Form.Group className={classes.formGroup}>
                            <Form.Label  >Password</Form.Label>
                            <Form.Control onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} placeholder="Enter your password address" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff", }} onKeyPress={handleKeyPress} />
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                            </button>
                        </Form.Group> */}
                    </Form>
                    <Button onClick={handleLogin} className={classes.snupbtn} disabled={isButtonDisabled}>
                        {loading ? (
                            <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Processing your request...</span>
                            </>
                        ) : (
                            "Reset Password"
                        )}
                    </Button>
                    {/* <p className={classes.lgin}>Forgot Password? <Link to={'/signup'} style={{ textDecoration: 'none' }}><span>Reset here!</span></Link></p> */}
                </div>
            </div>
        </div>
            <div className={classes.backgroundMobile}>
                
            <ToastContainer />
            <div className={classes.mainContainerMobile}>
                <div className={classes.thecntnt}>
                    <div className={classes.head}>
                        <p className={classes.SignUptxt}>Reset your  Passowrd</p>
                        <p className={classes.SignUptxtsub}>Enter your email to continue</p>
                    </div>
                    <Form>
                        <Form.Group className={classes.formGroup}>
                            <Form.Label  >Email Address</Form.Label>
                            <Form.Control onChange={(e) => setEmail(e.target.value)} type='text'  placeholder="Enter your email address" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff" }} />
                        </Form.Group>
                        {/* <Form.Group className={classes.formGroup}>
                            <Form.Label  >Password</Form.Label>
                            <Form.Control onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} placeholder="Enter your password address" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff", }} onKeyPress={handleKeyPress} />
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                            </button>
                        </Form.Group> */}
                    </Form>
                    <Button onClick={handleLogin} className={classes.snupbtn} disabled={isButtonDisabled}>
                        {loading ? (
                            <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Processing your request...</span>
                            </>
                        ) : (
                            "Reset Password"
                        )}
                    </Button>
                    {/* <p className={classes.lgin}>Forgot Password? <Link to={'/signup'} style={{ textDecoration: 'none' }}><span>Reset here!</span></Link></p> */}
                   
                </div>
            </div>
            </div>
            </>
            </div>
    );

}
export default ForgotPassword;