import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Active from '../../assets/NewDashImg/ActiveIcon.svg';
import Icon1 from '../../assets/NewDashImg/Icon1.svg';
import Icon2 from '../../assets/NewDashImg/Icon2.svg';
import Icon3 from '../../assets/NewDashImg/Icon3.svg';
import Icon4 from '../../assets/NewDashImg/Icon4.svg';
import Icon5 from '../../assets/NewDashImg/Icon5.svg';
import Icon6 from '../../assets/NewDashImg/Icon6.svg';
import classes from "./LatestMenu.module.css"

// import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Button, ButtonGroup, Form, Modal, Spinner } from 'react-bootstrap';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BASE_URL } from '../api/api';
import Select from 'react-select';

export default function LatestBudgetMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [createLoading, setCreateLoading] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [bearer, setBearer] = useState('');
  const [admin, setAdmin] = useState('');
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [subCat, setSubcat] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [navOpen, setNavOpen] = useState(false);




  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleNav = () => setNavOpen(!navOpen);

  const navToDashboard = () => {
    navigate('/budget_dash')
  }



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }


    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const uploadStatement = async () => {
    setCreateLoading(true);

    try {

      const formData = new FormData();
      formData.append('date', selectedDate);
      formData.append('file', selectedImage[0]);

      const response = await axios.post(
        `${BASE_URL}/quotes/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response.data?.message, "heeee");
      handleClose();
      // Reset form fields
      setSelectedImage("");
      setSelectedDate("");
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
      }
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const fetchSubCat = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const results = response.data?.data;
      setSubcat(results);

      //   console.log(results, "NIYIN");
    } catch (error) {
      const errorStatus = error?.response?.data?.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const opt = subCat.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));

  useEffect(() => {
    if (bearer) {
      fetchSubCat();
    }
  }, [bearer]);

  return (
    <div>
      <div className={classes.navSection}>
        <div className={classes.hamburger} onClick={toggleNav}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {/* <div className={classes.navSectionHead}>
                <span>
                    <small>Welcome</small>
                    <h4>Eniola Lawanson</h4>
                </span>
                <span>
                    <h4>Dashboard</h4>
                </span>
            </div> */}

        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Statement of Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ marginTop: 20 }}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  // autoFocus
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
                <div style={{ marginTop: 10 }} />
                <Form.Label className={classes.formLabelCustom}>Bank</Form.Label>
                <Select
                  className={classes.formControl}
                  options={opt}
                  placeholder="Select Bank"
                  onChange={handleAccountChange}
                  menuPortalTarget={document.body}
                  styles={{
                    width: "100%",
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '100px',
                      overflowY: 'auto',

                    }),
                  }}
                />


                <div style={{ marginTop: 10 }} />
                <Form.Label>File</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Choose file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={handleImageChange}

                />






              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Go back
            </Button>
            <Button variant="success" onClick={uploadStatement}>
              {createLoading ? (
                <>
                  <Spinner size='sm' />
                  <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                </>
              ) : (
                "Upload"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
        <div className={`$classes.navSectionNavCont} ${navOpen ? 'active' : ''}`}>
          <div className={classes.navSectionNav}>
            {/* <Dropdown> */}
            {/* <Dropdown.Toggle variant='success' className={classes.Dropdowns} id="dropdown-dashboard"> */}
            <Button onClick={navToDashboard} className={classes.Dropdowns}>
              <span className={classes.active}>
                {/* <img src={Icon3} alt='icon'/> */}
                Dashboard</span>
            </Button>
            {/* </Dropdown.Toggle> */}
            {/* <Dropdown.Menu className={classes.DropdownMenu}>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu> */}
            {/* </Dropdown> */}
            {/* <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-report1">
                <span>
                  
                  Setup</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={`${classes.DropdownMenu} ${classes.reportDropdownMenu}`}>
                <Dropdown.Item as={Link} to="/admin/users">Manage Users</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/salesrep">Manage Sales Rep</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/company">Manage Company</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/exchange_rate">Manage Currency</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/tax">Manage Tax</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables" style={{}}>
                <span >
                  {/* <img src={Icon2} alt='icon'/> */}
                  Setup</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                {/* <Dropdown.Item as={Link} to="/audit/continent">Manage Continents</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/region">Manage Region</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/province">Manage Province</Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/audit/user_management">Manage Users</Dropdown.Item>
              <Dropdown.Item as={Link} to="/audits/users">Assign Users to Province</Dropdown.Item>

               
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables" style={{}}>
                <span >
                {/* <img src={Icon2} alt='icon'/> */}
                  Transactions</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/audit/cash_management">Receipts</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/expenses">Expenses</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/lodged_transactions">Lodged Transactions</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/budget">Budget</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/payroll">Payroll</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

<Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables" style={{  }}>
                <span >
                  Province Records</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/audit/users">Customers</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/supplier">Suppliers</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/charts_of_account">Charts of Account</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/loan">Loan Beneficiaries</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables" style={{  }}>
                <span >
                  Charts of Account</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/budget/account_categories">Account Categories</Dropdown.Item>
                <Dropdown.Item as={Link} to="/budget/charts_of_account">Charts of Account</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

<Dropdown>
<Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables" style={{  }}>
                <span >
                  Bank Reconciliation</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/audit/account_categories">Account Categories</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/charts_of_account">Charts of Account</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/bank_statement">Bank Statements</Dropdown.Item>
                <Dropdown.Item as={Link} to="">Unmerged Items</Dropdown.Item>
                <Dropdown.Item as={Link} to="">Reconciliation Report</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-receivables">
                <span>
                  {/* <img src={Icon1} alt='icon'/> */}
                  Reports</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu} >
              <Dropdown.Item as={Link} to="/audit/cashbook">Cashbook</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/bank_statement">Bank Statement</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/trial_balance">Trial Balance</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/audit/general_ledger/postings">General Ledger</Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/audit/income_statement">Income Statement - Summary</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/income_statement_detailed">Income Statement - Detailed</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/activity_report">Account Activity Report</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/aged_analysis_payables">Age Analysis - Payables</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/aged_analysis_receivables">Age Analysis - Receivables</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/budget_statement_of_financial_position">Statement of Financial Position - Summary</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/financial_position_detail">Statement of Financial Position - Detailed</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/schedule_of_receivable">Schedule of Receivables</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/schedule_of_payable">Schedule of Payables</Dropdown.Item>
                <Dropdown.Item as={Link} to="/audit/bank_position_summary">Bank Position Summary</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/audit/statement_of_cashflow_summary_budget">Statement of Cash Flow</Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/audit/reconciliation_summary_report">Reconciliation Summary Reports</Dropdown.Item>
              </Dropdown.Menu>
              {/* <Dropdown.Menu className={classes.DropdownMenu} >
                <Dropdown.Item as={Link} to="/account/create_product">Add Products/Services</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/products">Product/Services List</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/unit_of_measurement">Unit of Measurement</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/product_categories">Product/ Services Category</Dropdown.Item>
              </Dropdown.Menu> */}
            </Dropdown>
          
          </div>
        </div>


      </div>
    </div>
  )
}
