import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import classes from './CreateSales.module.css';
import classes from '../../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../../Main Dashboard/MainDashoard';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import PayablesDash from '../../Payables Dashboard/PayablesDash';
import PaymentDash from '../../Payment Dashboard/PaymentDash';
import LatestDash from '../../LatestDashboard/LatestDash';
// import { BASE_URL } from '../../api/api';
// import classes from './LoanRepayment.module.css'
// import favicon from '../../Images/faviconn.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MobileMenu from '../../../Component/Mobile/MobileNav';


function PettyCashBulk() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [benBank, setBenBank] = useState([]);
  const [formData, setFormData] = useState([{ sn: 1, beneficiaryAccount: '', description: '', debitGlCode: '', amount: '', }]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [selectOptions2, setSelectOptions2] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadd, setLoadd] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [user, setUser] = useState('');
  // const [description, setDescription] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  const [userTypes, setUserTypes] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  // const [admin, setAdmin] = useState("");
  // const [userTypes, setUserTypes] = useState('');
  const [continent, setContinent] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [openNav,setOpenNav] = useState(false);


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');
      const current = await AsyncStorage.getItem('currentMonth');


      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setAdmin(value2);
      }

      if (admins !== null) {
        setUserTypes(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (current !== null) {
        console.log(current);
        const date = new Date(current);
      
        setCurrentMonth(date);
      
        const startOfDateRange = new Date(1900, 0, 1);
     
        const endOfDateRange = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      
        // Format date function as 'YYYY-MM-DD'
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); 
          const day = String(date.getDate()).padStart(2, '0');     
          return `${year}-${month}-${day}`;
        };
      
        setMinDate(formatDate(startOfDateRange));
        setMaxDate(formatDate(endOfDateRange));
      
        console.log("Date Range:", formatDate(startOfDateRange), "to", formatDate(endOfDateRange));
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);


  const uploadExcel = async () => {
    setUploadLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', selectedFile[0]);

      // console.log(selectedFile);

      const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${bearer}`,
      };

      const response = await axios.post(
        'https://api-sme.promixaccounting.com/api/v1/payment_voucher/post-journal',
        formData,
        { headers }
      );
      handleClose();
      navigate('/pending_payment_voucher');
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      console.log(response.data.message);
    } catch (error) {
      const errorStatus = error.response.data.message;

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });

      console.error(error);
    } finally {
      setUploadLoading(false);
    }
  };


  const handlePosting = async () => {
    setLoadd(true);
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };
      const beneficiaries = formData.map((row) => row.beneficiary).filter((name) => name !== undefined);
      const beneficiaryAccounts = formData.map((row) => row.beneficiaryAccount.value).filter((id) => id !== undefined);
      const debits = formData.map((row) => row.debitGlCode.value).filter((id) => id !== undefined);
      const descriptions = formData.map((row) => row.description).filter((id) => id !== undefined);
      const amounts = formData.map((row) => row.amount).filter((name) => name !== undefined);

      console.log(selectedBank, debits,beneficiaries ,"problem");
      const response = await axios.post(
        `${BASE_URL}/post-bulk-expenses`,
        {
          supplier_id: beneficiaries,
          // beneficiary_account_id: beneficiaryAccounts,
          debit_gl_code: debits,
          description: descriptions,
          amount: amounts,
          // description: description,
          transaction_date: selectedDate,
          payment_bank: selectedBank
        },
        { headers }
      );


      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });
      formData([]);
      // setDescription('');
      setSelectedDate('');
      setSelectedBank('');
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
        console.log(errorMessage);
      }
    } finally {
      setLoadd(false);
    }
  };
  const fetchBenAcct = async (selectedBeneficiary) => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/beneficiaryaccounts/getaccount?beneficiary_id=${selectedBeneficiary}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      const paid = response.data?.data || [];
      const benAcc = paid.map((item) => ({
        label: item.bank_name,
        value: item.id,
      }));
      // console.log(paid, 'paid');
      setBenBank(benAcc);
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };


  const handleFormChange = (value, field, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;
    setFormData(updatedFormData);
  };


  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };





  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchBeneficiaries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/beneficiary`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setTableData(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDebit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/bank-accounts`, { headers });
      const debitAcct = response.data?.data;

      const options1 = debitAcct.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData1(debitAcct);
      console.log(response)
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData1([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchExpenses = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/expenses`, { headers });
      const debt = response.data?.data;

      const opts = debt.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setSelectOptions1(opts);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setSelectOptions1([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAsset = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const assetsAcc = response.data?.data;
      const options2 = assetsAcc.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData2(assetsAcc);
      setSelectOptions2(options2);


    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    if (bearer) {
      fetchBeneficiaries();
      fetchDebit();
      fetchAsset();
      fetchExpenses();

    }
  }, [bearer]);




  const addRow = () => {
    const newRow = {
      sn: formData.length + 1,
      beneficiaryAccount: '',
      description: '',
      debitGlCode: '',
      amount: '',
    };

    setFormData(prevFormData => [...prevFormData, newRow]);
  };

  const handleValueChange1 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      amount: value,
    };
    setFormData(updatedFormData);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const fileList = Array.from(files);
    setSelectedFile(fileList);

  };

  const calculateTotalAmount = () => {
    const total = formData.reduce((total, row) => total + parseFloat(row.amount) || 0, 0);
    setTotalAmount(total);
    // console.log(totalQuantity);
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [formData]);



  return (

    <div>
  {
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    
      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>

            <div className={classes.finishedbodyCont}>
              <div className='newBody'>
                <div className={classes.newWidth}>

                  <Modal show={show} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                      <Modal.Title>Upload Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form style={{ marginTop: 20 }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Upload Excel File</Form.Label>
                          <Form.Control
                            type="file"
                            accept=".xlsx, .xls, .csv"
                            onChange={handleFileChange}
                          />

                        </Form.Group>
                      </Form>
                    </Modal.Body>






                    <Modal.Footer>
                      <Button variant="danger" onClick={handleClose}>
                        Go back
                      </Button>
                      <Button variant="success" onClick={uploadExcel}>
                        {uploadLoading ? (
                          <>
                            <Spinner size='sm' />
                            <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                          </>
                        ) : (
                          "Upload Payment"
                        )}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  15px" }}>Petty Cash - Bulk Entries</h3>
                  <p style={{ margin: " 0 10px 20px 15px" }}>Add, View, Edit, and Delete Petty Cash</p>

                  {/* <h3 style={{marginTop: '5rem', fontSize: 20, fontWeight: 700}}>Payment Voucher - Bulk Entries</h3> */}


                  <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont} ${classes.awrowRow} `}>
                      <div className={`card-body ${classes.awrowRow} `} style={{ border: 'none' }}>


                        <div className={`row ${classes.awrowRow} `}>

                          {/* <div className={`col-md-4 ${classes.mblrow}`}>
                            <div className="form-group row">
                              <label for="example-text-input" className={`col-sm-6 col-form-label font-weight-400 ${classes.mbllbl}`}>Select Bank:</label>
                              <div className="col-sm-6">
                                <Form.Select style={{ width: 300 }} name="customer" className={`form-control `} required="" value={selectedBank} onChange={handleBankChange} >
                                  <option value="">Select Bank</option>
                                  {tableData1.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.gl_name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </div>
                            </div>
                          </div> */}


                          <div style={{ marginTop: 2 }} />
                          <div className={`row ${classes.awrowRow} `}>

                            <div className={`col-md-4 ${classes.mblrow}`}>
                              <div className="form-group row">
                                <label for="example-text-input" className={`col-sm-6 col-form-label font-weight-400 ${classes.mbllbl}`}>Transaction Date:</label>
                                <div className="col-sm-6">
                                  <input style={{ width: 300 }} className={`form-control ${classes.widthmargin300}`}
                                    min={minDate}
                                    max={maxDate}
                                    required="" type="date" onChange={handleDateChange} name="date" value={selectedDate} />
                                </div>
                              </div>
                            </div>

                            <div style={{ marginTop: 20 }} />
                            <div className={`table-responsive ${classes.mblcol} ${classes.responsiveTTable}`}>
                              <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                <thead style={{ textAlign: "center", alignItems: "center" }}>
                                  <tr>
                                    <th>#</th>
                                    <th style={{ width: '30%' }}>Beneficiary</th>
                                    <th style={{ width: '30%' }}>Description</th>
                                    <th style={{ width: '22%', }}>Debit GL Code</th>
                                    <th style={{ width: '30%', }}>Amount</th>

                                    <th >
                                      <OverlayTrigger
                                        placement="top"
                                        className={classes.btndf1}
                                        overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add row</Tooltip>}
                                      >
                                        <Button style={{ borderRadius: 80 }} variant="primary" onClick={() => addRow()}>
                                          <i className="fas fa-plus"></i>
                                        </Button>
                                      </OverlayTrigger>
                                    </th>

                                  </tr>
                                </thead>
                                <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                  {formData.map((row, index) => (
                                    <tr key={index}>
                                      <td>{row.sn}</td>
                                      <td style={{ textAlign: 'left' }}>
                                      <input className="form-control" required="" type="text"  value={row.beneficiary} onChange={(e) => handleFormChange(e.target.value, 'beneficiary', index)} name="beneficiary" />
                                        {/* <Select
                                          options={selectOptions}
                                          onChange={(selectedOption) => handleFormChange(selectedOption, 'beneficiary', index)}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (provided) => ({
                                              ...provided,
                                              maxHeight: '300px',
                                              overflowY: 'auto',
                                            }),
                                          }}
                                        /> */}
                                      </td>
                                      <td>
                                        <textarea
                                          type="text"
                                          rows={1}
                                          className="form-control"
                                          value={row.description}
                                          onChange={(e) => handleFormChange(e.target.value, 'description', index)}
                                        />
                                      </td>

                                      <td>
                                        <Select
                                          options={selectOptions1}
                                          onChange={(selectedOption) => handleFormChange(selectedOption, 'debitGlCode', index)}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (provided) => ({
                                              ...provided,
                                              maxHeight: '300px',
                                              overflowY: 'auto',
                                            }),
                                          }}
                                        />
                                      </td>

                                      <td>
                                        <CurrencyInput
                                          name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                                          decimalsLimit={2}
                                          value={row.amount}
                                          className="form-control"
                                          onValueChange={(value) => handleValueChange1(value, index)}
                                          style={{ textAlign: "right", border: "none" }}
                                        />

                                      </td>

                                      <td style={{ textAlign: "left" }}>
                                        <OverlayTrigger
                                          placement="top"
                                          className={classes.btndf1}
                                          overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to delete row</Tooltip>}
                                        >
                                          <Button style={{ borderRadius: 80 }} variant="danger" onClick={() => deleteRow(index)}>
                                            <i className="far fa-trash-alt"></i>
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                            </div>
                          </div>

                          <div style={{ marginTop: 20 }} />
                          <div className={`col-md-6 ${classes.colMdtom}`}>
                            <div className={`form-group row justify-content-end ${classes.mblcol22}`}>
                              <label for="example-text-input" className={`col-sm-2 col-form-label font-weight-400 ${classes.labelTotal}`}>Total Amount:</label>
                              <div className={`col-sm-12 ${classes.tontinDiv}`} >                                <CurrencyInput
                                name="total-amount"
                                decimalsLimit={2}
                                className="form-control"
                                value={totalAmount}
                                disabled
                                style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                              />
                              </div>
                            </div>

                          </div>

                        </div>


                        <div class={`modal-footer ${classes.overlaybtn100Row}`}>
                          <Button style={{ borderRadius: 0, }} variant='success' className={classes.longbtn12} onClick={handlePosting}>
                            {loadd ? (
                              <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                              </>
                            ) : (
                              "Save Payments"
                            )}
                          </Button>

                        </div>
                      </div>
                    </div>
                  </div>













                </div>
                {/* <!--/.main content--> */}
              </div>
            </div>
          </div>
          {/* <InfoFooter /> */}
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}


      </div>
    </div>

  );
}

export default PettyCashBulk;